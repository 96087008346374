/**
 * Global Router configure for CMP
 *
 * @author Allex Wang
 */

import Vue from 'vue'
import VueRouter, { Route } from 'vue-router'
import { isFunction, noop, setQueryParams } from '@tdio/utils'

import { genModuleEntryUrl, setEntries, open, isPureRoute } from './utils'
import { constRoutes, LOGIN_SERVICE } from './config'
import { RouteType, RouteConfig, ContextType, GenericLocation } from './types'
import init from './initRouter'

export { VueRouter, RouteType, ContextType, isPureRoute }

declare module 'vue-router/types/router' {
  interface VueRouter {
    matcher: any;
    open (path: GenericLocation | string): void;
    genModuleEntryUrl (type: string, params?: any, query?: Kv): string;
    setEntries (entries: RouteConfig[]): void;
    doLogin (route?: Route): void;
    doLogout (route?: Route): void;
  }
}

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function (...args: any) {
  const p: Promise<any> = originalPush.apply(this, args)!
  return isFunction(p?.catch) ? p.catch(noop) : p
}

Vue.use(VueRouter)

const createRouter = (): VueRouter => {
  const self = new VueRouter({
    routes: constRoutes,
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: () => ({ x: 0, y: 0 })
  })

  // Api for update entries lazy, also update global route keys
  self.setEntries = (entries: RouteConfig[]): void => {
    setEntries(entries)
    self.addRoutes(entries)
  }

  // Add helper for generate module entry url
  self.genModuleEntryUrl = genModuleEntryUrl.bind(self)
  self.open = open.bind(self)

  /* Unify login & logout entries */

  // Helper for redirect sso login
  self.doLogin = function (this: VueRouter, r?: Route) {
    let path = ''
    let fullPath = ''

    r = r || this.app.$route
    if (r) {
      ({ path, fullPath } = r)
    }

    if (path !== LOGIN_SERVICE) {
      this.open({
        path: setQueryParams(LOGIN_SERVICE, 'returnURL', fullPath),
        query: { redirect: fullPath },
        replace: true
      })
    }
  }

  self.doLogout = function (this: VueRouter, r?: Route) {
    return this.doLogin(r)
  }

  return self
}

const router: VueRouter = createRouter()

/**
 * Provide an api for init router inteceptor
 */
export function initRouter () {
  return init(router)
}

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter () {
  const r = createRouter()
  router.matcher = r.matcher // reset router
}

export default router

/**
 * Shortcut for login
 */
export const toLogin = (r?: Route) => router.doLogin(r)
