import {
  unset, deepClone, isArray, isEmpty, get, isValue
} from '@tdio/utils'
import { setDefaults, create, ApiFactory as ApiFactoryInter, resolveUrl, IRequestConfig } from '@tdio/io'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

export { resolveUrl }

export interface ISettings extends IRequestConfig {
  errorHandle: (e: Error) => void;
}

const PARAMS_XARGS = 'params.$xargs'

const settings: ISettings = {
  baseURL: '/api',
  timeout: 1000 * 30,
  errorHandle (e: Error) {
    console.error(e)
  }
}

const http = {
  ...create(),

  setup: (cfg: Partial<ISettings>) => {
    Object.assign(settings, cfg)

    // dequeue requests, close loading optionally
    const requestQueue: any[] = []
    const ensureRequestEnd = () => {
      if (!(requestQueue.pop(), requestQueue.length)) {
        NProgress.done()
      }
    }

    // set io defaults globally (for all of instances)
    setDefaults({
      ...settings,

      onReq (req) {
        if (!get(req, `${PARAMS_XARGS}.silent`)) {
          NProgress.start()
          requestQueue.push(req)
        }
        unset(req, PARAMS_XARGS)
        return req
      },
      onRes (res) {
        const { no: page, size, total } = res.data
        if ([page, size, total].some(v => isValue(v))) {
          res.data.pager = { page, size, total }
        }
        ensureRequestEnd()
        return res
      },
      onErr (err) {
        ensureRequestEnd()
        settings.errorHandle(err)
      }
    })
  }
}

export default http

/**
 * Normalize common search query parameters for GET method
 * eg: { searchProp: 'p1', searchValue: 'v1', ... } => { 'p1': 'v1', ... }
 */
function normalizeSearchParams <T extends any> (params: T): T {
  params = deepClone(params)
  if (params.query) {
    const query = params.query
    const { sortProp, sortOrder } = params.query
    if (isValue(sortProp)) {
      const orderMap: any = {
        ascending: 'asc',
        descending: 'desc'
      }
      if (isValue(sortOrder)) {
        query.col = sortProp
        query.order = orderMap[sortOrder]
      }
      delete query.sortProp
      delete query.sortOrder
    }
  }
  return params
}

export const ApiFactory = (options?: ApiFactoryConfig) => ApiFactoryInter({
  configReducer (config) {
    const { method, params } = config
    // normalize GET params
    if (method === 'get' && !isEmpty(params)) {
      config.params = normalizeSearchParams(params)
    }
    return config
  },
  ...options
})
