/**
 * Provide some pure functional utility
 *
 * @author Allex Wang
 */

import { resolveUrl } from '@tdio/io'
import {
  isArray,
  isNumeric,
  isString,
  isValue,
  memoize
} from '@tdio/utils'

const stripClassRE = /[.#+<>'"`]+/g

export const safetyClassName = memoize(s => s.replace(stripClassRE, '-').toLowerCase())

/**
 * Camelize a hyphen-delimited string.
 */
const camelizeRE = /-(\w)/g

export const camelize = memoize(str => str.replace(camelizeRE, (_, c) => (c ? c.toUpperCase() : '')))

/**
 * Capitalize a string.
 */
export const capitalize = memoize(str => str.charAt(0).toUpperCase() + str.slice(1))

/**
 * Hyphenate a camelCase string.
 */
const hyphenateRE = /\B([A-Z])/g

export const hyphenate = memoize(str => str.replace(hyphenateRE, '-$1').toLowerCase())

/**
 * Parse boolean value by string text
 * @param {String} v
 */
const RE_BOOL_KEYS = /^(true|yes|on|1)$/

export function parseBool (v: string): boolean {
  return isString(v) ? RE_BOOL_KEYS.test(v) : !!v
}

export function collectEnumKeys (enumObject: any): string[] {
  return Object.values(enumObject).filter(value => typeof value === 'string') as string[]
}

export const getGradientColor = (startColor: string, endColor: string, percent) => {
  // strip the leading # if it's there
  startColor = startColor.replace(/^\s*#|\s*$/g, '')
  endColor = endColor.replace(/^\s*#|\s*$/g, '')

  // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
  if (startColor.length === 3) {
    startColor = startColor.replace(/(.)/g, '$1$1')
  }

  if (endColor.length === 3) {
    endColor = endColor.replace(/(.)/g, '$1$1')
  }

  // get colors
  const startRed = parseInt(startColor.substr(0, 2), 16)
  const startGreen = parseInt(startColor.substr(2, 2), 16)
  const startBlue = parseInt(startColor.substr(4, 2), 16)

  const endRed = parseInt(endColor.substr(0, 2), 16)
  const endGreen = parseInt(endColor.substr(2, 2), 16)
  const endBlue = parseInt(endColor.substr(4, 2), 16)

  // calculate new color
  let diffRed: number | string = endRed - startRed
  let diffGreen: number | string = endGreen - startGreen
  let diffBlue: number | string = endBlue - startBlue

  diffRed = (diffRed * percent + startRed).toString(16).split('.')[0]
  diffGreen = (diffGreen * percent + startGreen).toString(16).split('.')[0]
  diffBlue = (diffBlue * percent + startBlue).toString(16).split('.')[0];

  // ensure 2 digits by color
  (diffRed.length === 1) && (diffRed = `0${diffRed}`);
  (diffGreen.length === 1) && (diffGreen = `0${diffGreen}`);
  (diffBlue.length === 1) && (diffBlue = `0${diffBlue}`)

  return `#${diffRed}${diffGreen}${diffBlue}`
}

// 清除空值
export const trimEmpty = val => ((!!val || val === 0) && val !== 'undefined' ? val : '')

// 空值或'undefined'处理
export const getValidVal = (...args) => (
  args.reduce((prev, next) => {
    prev = trimEmpty(prev)
    next = trimEmpty(next)
    return `${prev}${(!prev || !next) ? '' : '/'}${next}`
  })
)

export const resolveImageUrl = (s: string) => (/^https?:\/\//.test(s) ? s : resolveUrl(`/uploads/${s}`))
