import { Location } from 'vue-router'

export enum RouteType {
  Top,
  SideNav,
  Hidden
}

export enum ContextType {
  Tenant = 1,
  Platform = 2,
  System = 3
}

export type RouteConfig = import('vue-router').RouteConfig & {
  hidden?: boolean;
  shadow?: boolean;
}

// Generic location add with native external sites support (Compatible with router {Location})
// handle as native location if a absolute path eg: http://foo.com/path
export type GenericLocation = Location & {
  url?: string;
  key?: string; // for route global identify `meta.globalKey`
  external?: boolean;
  newopen?: boolean;
}
