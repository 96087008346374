import loginLocales from './login'
import userLocales from './user'
import validatorRuleMessages from './validator'

const commonActions = {
  Actions: '操作',
  Cancel: '取消',
  Reset: '重置',
  Submit: '提交',
  New: '添加',
  Add: '添加',
  Edit: '编辑',
  Modify: '修改',
  Delete: '删除',
  View: '查看',
  Enable: '启用',
  UnEnable: '不启用',
  Disable: '禁用',
  Pause: '暂停',
  More: '更多',
  Search: '搜索',
  Save: '保存',
  Confirm: '确定',
  Detail: '详情',
  Back: '返回',
  Test: '测试',
  Close: '关闭',
  Refresh: '刷新',
  Clone: '克隆',
  Copy: '复制',
  Manage: '管理',
  Deploy: '部署',
  Redeploy: '重新部署',
  Start: '启动',
  Restart: '重启',
  Stop: '停止',
  Rollback: '回滚',
  Config: '配置',
  Build: '构建',
  'Build Now': '立即构建',
  Upload: '上传',
  Download: '下载',
  Click: '点击',
  Select: '选择',
  ActionTip: '操作提示',
  Loading: '加载',
  'Loading...': '加载中...',
  'Add Row': '添加一行',
  'Test Pass': '测试通过',
  'Select...': '请选择',
  'Action Success': '操作成功',
  'Copy Success': '复制成功',
  'confirm.delete': '您确定要删除所选%{target}？',
  'confirm.action': '%{prefix}您确定要%{action}%{target}？',
  'Select All': '全选',
  'Click Upload': '点击上传'
}

const commonMenus = {
  Workbench: '工作台',
  'Global Settings': '平台设置',
  Home: '首页',
  Overview: '概况',
  'System Manage': '系统管理',
  'User Manage': '用户管理',
  'Role Manage': '角色管理',
  'Platform Setting': '平台设置',
  'Channel Setting': '通道设置',
  'Version License': '版本授权',
  'Role Auth': '角色权限',
  'User Center': '用户中心',
  'Personal Center': '个人中心'
}

export {
  commonMenus,
  commonActions,
  validatorRuleMessages, // common rules: rule.*
  loginLocales,
  userLocales
}
