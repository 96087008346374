import Cookies from '@allex/cookie'
import { GetterTree } from 'vuex'
import { RouteConfig } from 'vue-router'
import { pick, resolveUrl, noopAwait } from '@tdio/utils'

export interface AppState {
  ready: boolean;
  sidebar: {
    opened: boolean;
    withoutAnimation: boolean;
  },
  activeRoute: RouteConfig;
  language: string;
  rootPath: string;
  topPrefix: string;
}

const SIDEBAR_STATUS_KEY = 'sidebarStatus'

type T = AppState

const state: T = {
  ready: false,
  sidebar: {
    opened: Cookies.get(SIDEBAR_STATUS_KEY) ? !!+Cookies.get(SIDEBAR_STATUS_KEY) : true,
    withoutAnimation: false
  },
  language: Cookies.get('language') || 'en',

  // current actived route
  activeRoute: null,

  // Application root path
  rootPath: '/',

  // current topnav route path (fullpath)
  topPrefix: ''
}

export const getters: GetterTree<T, RootState> = {
  ready (state: T): boolean {
    return state.ready
  },

  rootPath (state: T): string {
    return state.rootPath
  },

  topPrefix (state: T): string {
    return state.topPrefix
  },

  // resolve path relative current rootPath
  resolvePath (state: T) {
    return (path: string): string => {
      const base = state.rootPath.replace(/\/*$/, '')
      return resolveUrl(base, path, { append: true })
    }
  },

  /**
   * Provide shortcut API for application level params. (whitelist mode)
   */
  globalParams (state: T, getters, rootState, rootGetters): Kv {
    return pick(getters, ['rootPath'])
  }
}

const mutations = {
  TOGGLE_SIDEBAR: (state: T) => {
    state.sidebar.opened = !state.sidebar.opened
    state.sidebar.withoutAnimation = false
    Cookies.set(SIDEBAR_STATUS_KEY, state.sidebar.opened ? 1 : 0)
  },
  CLOSE_SIDEBAR: (state: T, withoutAnimation: boolean) => {
    Cookies.set(SIDEBAR_STATUS_KEY, 0)
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  SET_LANGUAGE: (state: T, language: string) => {
    state.language = language
    Cookies.set('language', language)
  },

  setReady (state: T, ready: boolean) {
    state.ready = !!ready
  },
  syncActiveRoute (state: T, route: RouteConfig) {
    state.activeRoute = route
  },
  setRootPath (state: T, path: string) {
    state.rootPath = path
  },
  setTopPrefix (state: T, path: string) {
    state.topPrefix = path
  }
}

const actions = {
  toggleSideBar ({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  closeSideBar ({ commit }, { withoutAnimation }: { withoutAnimation: boolean }) {
    commit('CLOSE_SIDEBAR', withoutAnimation)
  },
  setLanguage ({ commit }, language: string) {
    commit('SET_LANGUAGE', language)
  },
  async syncActiveRoute ({ commit }, { from, to }: { from: RouteConfig; to: RouteConfig; }) {
    commit('syncActiveRoute', to)
    await noopAwait()
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
