import { AvailabilityStatus, IUserAuthInfo, IUserInfo } from '../types/user'

type A = IUserAuthInfo;

export enum UserStatus {
  Enable = 1,
  Disabled = 2
}

export enum UserType {
  Platform = 1,
  Tenant = 2
}

export enum ComponentMode {
  Platform,
  Tenant
}

export class UserInfo implements IUserInfo {
  id?: string | undefined;
  username!: string;
  realName!: string;
  email?: string | undefined;

  nickName!: string;
  parentId!: number;
  domainId!: number;
  appId!: number;
  availabilityStatus!: AvailabilityStatus;
  isMaster!: boolean;
  canSignIn!: boolean;
  mobilePhone?: string | undefined;
  description!: string;
  creationTime!: number;
  updationTime!: number;
}
