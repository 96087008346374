import Vue from 'vue'
import Vuex, { GetterTree } from 'vuex'

import app from './app'
import settings from './settings'

type S = Kv

Vue.use(Vuex)

const getters: GetterTree<S, RootState> = {
  sidebar: (state: S) => state.app.sidebar,
  language: (state: S) => state.app.language
}

export default new Vuex.Store({
  getters,
  modules: {
    app,
    settings
  }
})
