export function isExternal (path: string): boolean {
  return /^(https?:|mailto:|tel:)/.test(path)
}

export function validURL (url: string): boolean {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return reg.test(url)
}

export function validLowerCase (str: string): boolean {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

export function validUpperCase (str: string): boolean {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

export function validAlphabets (str: string): boolean {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}

export function validEmail (email: string): boolean {
  const reg = /^(([^<>()[]\\.,;:\s@"]+(\.[^<>()[]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return reg.test(email)
}

export const createRegExp = (s: string, pattern: '' | 'i' | 'g' | 'm' = '') => new RegExp(s, pattern)

export const RE_CHAR_SPEC = "-_&:;'<>,=%`~!@#()$^*+|{}[\\].?"

export const RE_CHAR_NORM = 'a-zA-Z0-9'

const pwdRules: Kv<RegExp> = {
  number: /(?=.*[0-9].*)/,
  capital: /(?=.*[A-Z].*)/,
  lowercase: /(?=.*[a-z].*)/,
  character: createRegExp(`(?=.*[${RE_CHAR_SPEC}].*)`)
}

// Returns the intensity of password
export const calcPasswdStrength = (s: string): number => Object.values(pwdRules).reduce((n, reg) => n + Number(reg.test(s)), 0)
