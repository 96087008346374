import { md5 } from '@allex/md5'
import { encode as toBase64 } from '@allex/base64'

const reverse = (s: string): string => s.split('').reverse().join('')

export const encrypt = (p: string): string => {
  if (p == null || p === '') {
    return ''
  }
  p = String(p)
  return md5(`$MD5:${toBase64(p)}`)
}
