import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { RouteConfig } from 'vue-router'
import { resolveUrl, merge } from '@tdio/utils'
import { resolveRoutePath } from 'route-utils'

import { isExternal } from '@/utils/validate'

import FixiOSBug from './FixiOSBug'
import Item from './Item'
import Link from './Link'

const resolve = (route: RouteConfig, base: string, params: Kv): string => {
  if (isExternal(route.path)) {
    return route.path
  }
  return resolveRoutePath(route, { base, params, append: true })
}

@Component({
  name: 'RouteItem',
  mixins: [FixiOSBug],
  components: { Item, Link }
})
export default class RouteItem extends Vue {
  @Prop({ type: String, default: '' })
  basePath!: string;

  @Prop({ type: Object, required: true })
  item!: RouteConfig;

  @Prop({ type: Object, default: () => {} })
  params!: string;

  @Prop(Boolean)
  isNest!: boolean;

  @Prop({ type: Boolean, default: true })
  showIcon!: boolean;

  @Prop(Boolean)
  disabled!: boolean;

  get showNestIcon (): boolean {
    const children = this.item.children || []
    return children.length < 2 || children.every(o => o.meta && o.meta.icon)
  }

  render (h) {
    const {
      item,
      isNest,
      showIcon,
      basePath,
      params,
      disabled
    } = this

    if (item.hidden) {
      return null
    }

    const orphan = this.checkOrphanChild(item.children, item)

    return (
      <div>
        {
          (orphan && (!orphan.children || orphan.noShowingChildren) && !item.alwaysShow)
            ? (
              <Link to={resolve(orphan, basePath, params)}>
                <el-menu-item disabled={disabled} index={resolve(orphan, basePath, params)} class={{ 'submenu-title-noDropdown': !isNest }}>
                  {
                    orphan.meta
                      ? (
                        <Item
                          icon={showIcon && (orphan.meta.icon || (item.meta && item.meta.icon)) || ''}
                          title={$t(orphan.meta.title || orphan.meta.name)}
                        />
                      ) : null
                  }
                </el-menu-item>
              </Link>
            )
            : (
              <el-submenu ref="subMenu" index={resolve(item, basePath, params)} popperAppendToBody={true} disabled={disabled}>
                <template slot="title">
                  {
                    item.meta ? (<Item icon={item.meta.icon} title={$t(item.meta.title || item.meta.name)} />) : null
                  }
                </template>
                {
                  item.children.reduce(({ base, list }, r) => {
                    if (!r.hidden) {
                      const fullPath = resolve(r, base, params)
                      list.push(
                        <RouteItem
                          key={fullPath}
                          item={r}
                          basePath={base}
                          params={params}
                          isNest
                          showIcon={this.showNestIcon}
                          class="nest-menu"
                        />
                      )
                    }
                    return { base, list }
                  }, { base: resolve(item, basePath, params), list: [] }).list
                }
              </el-submenu>
            )
        }
      </div>
    )
  }

  private checkOrphanChild (children: RouteConfig[] = [], parent: RouteConfig): RouteConfig | null {
    let child: RouteConfig | null = null

    const items = children.filter(item => !item.hidden)
    const {
      path: basePath,
      redirect: baseRedirect,
      component,
      children: baseChildren,
      ...baseRest
    } = parent

    if (items.length === 1) {
      // When there is only one child router, the child router is displayed by default
      const { redirect, path, ...rest } = items[0]
      let p = resolveUrl(basePath, path, { append: true, leadingSlash: false })
      if (redirect) {
        p = resolveUrl(p, redirect, { leadingSlash: false })
      }
      child = merge(baseRest, rest, { path: p })
    } else if (!items.length) {
      // Show parent if there are no child router to display
      child = {
        ...baseRest,
        component,
        children: baseChildren,
        path: baseRedirect || basePath,
        noShowingChildren: true
      }
    }

    return child
  }
}
