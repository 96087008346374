const userLocaleMessages = {
  'About Product': '关于产品',
  'Product Name': '产品名称',
  'Product Version': '产品版本',
  'Running Time': '运行时间',
  'Remaining Time': '剩余时间',
  'Activate Product Serial Number': '激活产品序列号',
  'Please input product serial number': '请输入产品序列号',
  'Get Product Serial Number': '获取产品序列号',
  'How to get product serial number': '如何获取产品序列号?',
  'Get product serial number tips': '请复制下面的申请码，发送给产品对接人，获得产品序列号：',
  'User Profile': '个人设置',
  'Are you sure to logout?': '您确定退出登录？',
  User: '用户',
  UserName: '用户名',
  Username: '用户名',
  FullName: '真实姓名',
  'Full Name': '真实姓名',
  Role: '角色',
  Phone: '手机号',
  Mobile: '手机号',
  Email: '邮箱',
  Authorize: '授权',
  Status: '状态',
  Remark: '备注',
  'Reset Password': '重置密码',
  'Admin Password': '管理员密码',
  'Confirm Password': '确认密码',
  'New Password': '新密码',
  'Old Password': '旧密码',
  'New User': '添加用户',
  'Delete User': '删除用户',
  'Edit User': '编辑用户',
  'Auth User': '授权用户',

  RoleName: '角色名',
  'New Role': '添加角色',
  'Delete Role': '删除角色',
  'Edit Role': '编辑角色',
  'No Available Roles': '无可用角色'
}

export default userLocaleMessages
