/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
 *                                if not set alwaysShow, only more than one route under the children
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
 *   roles: ['admin','editor']    will control the page roles (you can set multiple roles)
 *   title: 'title'               the name show in sub-menu and breadcrumb (recommend set)
 *   icon: 'svg-name'             the icon show in the sidebar
 *   noCache: true                if true, the page will no be cached(default is false)
 *   breadcrumb: false            if false, the item will hidden in breadcrumb(default is true)
 *   affix: true                  if true, the tag will affix in the tags-view
 *   type: {RouteType}            optional set the type of the route for business nav aspect
 *   globalKey: 'tenantDetails'   optional set global key for api#genModuleEntryUrl()
 *   checkACL: boolean            optional set true to allow accessable w/o acl checking
 * }
 */

import { RouteConfig } from './types'

// App SSO login entry
export const LOGIN_SERVICE = '/login'

/**
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constRoutes: RouteConfig[] = [
  { path: LOGIN_SERVICE, component: () => import('@/views/login/Login.vue'), hidden: true, meta: { name: '登录' } },
  { path: '/404', component: () => import('@/views/errorPage/404.vue'), hidden: true },
  { path: '/403', component: () => import('@/views/errorPage/403.vue'), hidden: true }
]
