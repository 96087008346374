import defaultSettings from '@/config/settings'
import getConfig from '@/config/getConfig'

const { showSettings, fixedHeader, sidebarLogo, darkMode } = defaultSettings

const state = {
  showSettings,
  fixedHeader,
  sidebarLogo,

  // ui embed mode (hide topnav & sidebar)
  uiEmbed: getConfig('uiEmbed'),

  // dark mode
  darkMode
}

const mutations = {
  CHANGE_SETTING: (state: any, { key, value }: any) => {
    if (Object.prototype.hasOwnProperty.call(state, key)) {
      state[key] = value
    }
  }
}

const actions = {
  changeSetting ({ commit }: any, data: any) {
    commit('CHANGE_SETTING', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
