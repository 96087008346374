<template>
  <div class="m-hamburger" @click="toggleClick">
    <IconToggle :class="{'is-active':isActive}" class="icon" />
  </div>
</template>

<script>
import IconToggle from './toggle.svg'

export default {
  name: 'Hamburger',
  components: { IconToggle },
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleClick () {
      this.$emit('toggleClick')
    }
  }
}
</script>

<style lang="scss">
.m-hamburger {
  .icon {
    display: inline-block;
    vertical-align: middle;
    margin-top: 2px;
    width: 18px;
    height: 18px;
    &.is-active {
      transform: rotate(180deg);
    }
  }
}
</style>
