export const basicDataFields = {
  Creator: '创建者',
  Name: '名称',
  Description: '描述',
  'Created Time': '创建时间',
  'Updated Time': '更新时间',
  'Start Date': '开始日期',
  'Start Time': '开始时间',
  'End Date': '结束日期',
  'End Time': '结束时间',
  Date: '日期'
}
