import Vue, { VNode } from 'vue'
import { Component, Watch, Prop } from 'vue-property-decorator'
import { Route, RouteRecord } from 'vue-router'
import { resolveRoutePath } from 'route-utils'
import { get, resolveUrl, isFunction } from '@tdio/utils'
import { Button, Icon } from '@tdio/tdui'

import { isPureRoute } from '@/router/utils'
import { RouteType } from '@/router/types'

import './Breadcrumb.scss'

type BreadcrumbRouteRecord = RouteRecord & { title: string; }
type RouteTitleGetter = (route: Route) => string;

const getMetaTitile = (r: RouteRecord): string => {
  const meta = r.meta
  let t = meta && (meta.title || meta.name) || ''

  // retrieve title by parent route if current path is empty
  if (!t && r.parent) {
    t = getMetaTitile(r.parent)
  }

  return t || ''
}

const getRouteTitle = (r: RouteRecord, route: Route): string => {
  let title = ''

  const titleGetter = get<RouteTitleGetter>(r, 'components.default.getRouteTitle')
  if (titleGetter && isFunction(titleGetter)) {
    title = titleGetter(route)
  }

  return title || getMetaTitile(r)
}

const getRoutePath = (r: RouteRecord, params: Kv): string => {
  const { path, redirect } = r
  let p = redirect
    ? resolveUrl(path, redirect as string, { leadingSlash: false, append: false })
    : path
  p = resolveRoutePath(p, params)
  return p || ''
}

const trimRightSlash = (s: string): string => (s !== '/' ? s.replace(/\/+$/, '') : s)

@Component
export default class Breadcrumb extends Vue {
  paths: BreadcrumbRouteRecord[] = []

  @Prop({ type: Boolean, default: true })
  showRoot!: boolean;

  @Watch('$route', { immediate: true })
  handleRoute (to: Route) {
    const { items, params } = this.getLevelPaths()
    let paths = items
    if (!this.showRoot) {
      paths = paths.filter(r => r.meta?.type !== RouteType.Top)
    }
    this.paths = paths
    this.params = params
  }

  getLevelPaths (): { items: BreadcrumbRouteRecord[]; params: Kv; } {
    const { $route } = this
    const { params, matched } = $route
    const paths: Kv<number> = {}
    const names: kv<string> = {}

    const items = matched.reduceRight((list, r) => {
      const path = trimRightSlash(getRoutePath(r, { params }))
      const title = getRouteTitle(r, $route)
      if (!paths[path] && !names[title]) {
        const p = r.redirect == null && isPureRoute({ component: r.components.default }) ? '' : path
        if (p) {
          paths[path] = 1
          names[title] = 1
          list.unshift({ ...r, title, path: p })
        }
      }
      return list
    }, [] as BreadcrumbRouteRecord[])

    return { items, params }
  }

  render (): VNode {
    const currpath = this.$route.path
    return (
      <el-breadcrumb class="m-breadcrumb" separatorClass="el-icon-arrow-right">
        <Button class="btn-back" type="text" onClick={() => this.$router.go(-1)}>
          <Icon icon-name="goback.svg" class="ico-back" />
        </Button>
        <span class="sep"></span>
        {
          this.paths.map(({ path, title }) => <el-breadcrumb-item to={path === currpath ? '' : path}>{$t(title)}</el-breadcrumb-item>)
        }
      </el-breadcrumb>
    )
  }
}
