import { createConfigWorker } from '@tdio/ui-config'

import { getQuery } from '@/utils'

import settings from './settings'

const cfgWorker = createConfigWorker({
  env: process.env,
  initial: settings,
  spec: {
    uiEmbed: {
      initial: false,
      getter () {
        return getQuery<string>('uiEmbed')
      }
    }
  }
})

export const getEnv = cfgWorker.getEnv

export default function getConfig <T> (ns?: string, defval?: T): T {
  return cfgWorker.getConfig(ns, defval)!
}

export const setConfig = <T> (ns: string, val: T): void => {
  cfgWorker.setConfig(ns, val)
}
