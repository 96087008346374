import { RouteConfig } from 'vue-router'
import { LooseKeyMap } from 'loose-key-map'
import {
  hasOwn, isEmpty, isArray, isString, isValue, isBoolean, get, resolveUrl
} from '@tdio/utils'
import { Acl } from '@tdio/acl-helper'

import router from '@/router'
import { aclTables } from '@/config/aclTables'
import store from '@/store'
import { getEnv } from '@/config/getConfig'

import { matchModuleBWL } from './moduleAccess'

export enum PermissionVal { False, Disabled, True }

export interface IPermission {
  name: string;
  code: string;
  value: PermissionVal;
}

export type ACLTable = LooseKeyMap<string, IPermission>

// Normalize generic permission values
export const parsePermissionVal = (v: number): PermissionVal =>
  (hasOwn(PermissionVal, v) ? v as PermissionVal : PermissionVal.False)

export class Permission implements IPermission {
  value: PermissionVal
  constructor (
    readonly name: string,
    readonly code: string,
    value: PermissionVal | boolean | null
  ) {
    this.value = parsePermissionVal(isBoolean(value) && value ? PermissionVal.True : Number(value))
  }
}

/**
 * Normalize raw permission meta data, a single acl code may map to more than one permissions
 *
 * eg:
 *
 *  100/231|2
 *  => [{ name: 'sys/foo', code: '100/231', value: PermissionVal.True }, ...]
 */
const parsePermission = (raw: string): IPermission[] => {
  const tuple = raw.split('|')
  const [k, v] = tuple
  if (!hasOwn(aclTables, k)) {
    return []
  }
  const spec: string | string[] = aclTables[k]

  // Identify to `PermissionVal.True` if permission value not provided (last bit empty)
  return (!isArray(spec) ? [spec] : spec)
    .filter(Boolean)
    .map(p => new Permission(p, k, isValue(v) ? +v : true))
}

/**
 * Helper api for map permissions to construct ACLTable
 *
 * ```
 * [ '100/454/002:2', '100/231/012:1', ... ]
 * ```
 *
 * @param {String[]} permissions The original permissions from backend.
 *
 * @returns ACLTable
 */
export const mapACLTables = (permissions: string[]): ACLTable =>
  permissions.reduce((o, v) => {
    const entities = parsePermission(v)
    if (entities) {
      entities.forEach(entity => o.set(entity.name, entity))
    }
    return o
  }, new LooseKeyMap<string, IPermission>([['/', new Permission('/', '/', !!permissions.length)]]))

const checkBuiltinACL = (route: Route | RouteConfig) => get<boolean>(route, `meta.checkACL`, true) === false

const aclHelper = new Acl(aclTables, { router, routeACLChecker: checkBuiltinACL })

/**
 * Check a specific resource permission
 *
 * @param {String} path The resource name, can be a namespace path
 * @param {ACLTable} aclTables Optional provide an ACL tables
 *
 * @returns {Boolean}
 */
export const checkACL = aclHelper.checkACL.bind(aclHelper)

/**
 * Map reduce route tables, Returns a new route tables base on user ACL.
 *
 * @param routes asyncRoutes
 * @param acl
 */
export function reduceRoutes (routes: RouteConfig[], acl: ACLTable): RouteConfig[] {
  const reduce = (routes: RouteConfig[], acl: ACLTable, root: string = '/', preCheck = false, parentAdded = false) => routes.reduce((list, route) => {
    const { path } = route
    const p = resolveUrl(root, path || '', true)
    let {
      children = [],
      ...tmp
    } = route

    let added = false
    let isBwMatched = true

    // Add to route tables if:
    // -----------------------
    // 1. Empty route (is a virtual route, path is empty)
    // 2. Invisible route (explicit hidden)
    // 3. Accessible in ACL table (NOT PermissionVal.False), and matched by module gateway B & W list

    if (
      (p === '/' || (path === '' && parentAdded))
      || (!preCheck && (!path || route.hidden))
      || ((checkACL(p, acl) !== PermissionVal.False || checkBuiltinACL(route)) && (isBwMatched = matchModuleBWL(p)))
    ) {
      list.push(tmp)
      added = true
    }

    // 5. Fixup route if children not empty (ensure the parent node accessable)
    if (isBwMatched && children.length > 0) {
      children = reduce(children, acl, p, true, added)
      if (children.length > 0) {
        tmp.children = children
        // fixup parent node
        if (!added) {
          list.push(tmp)
        }
      }
    }

    return list
  }, [] as RouteConfig[])

  return reduce(routes, acl)
}

export const checkBlackFeature = (key: string, loose?: boolean = false): boolean => store.getters['session/assertBlackFeature'](key, loose)
