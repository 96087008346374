/* eslint import/no-cycle: off */

import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RouteConfig } from 'vue-router'
import { findNodeRef } from 'route-utils'
import { get, set, isArray, isFunction, isEmpty } from '@tdio/utils'
import cookie from '@allex/cookie'

import { encrypt as encryptPassword } from '@/utils/crypto'
import api from '@/utils/api'
import router, { resetRouter, isPureRoute } from '@/router'
import { findFAP } from '@/router/utils'
import { constRoutes, asyncRoutes } from '@/router/routes'
import { reduceRoutes, checkACL, ACLTable } from '@/components/ACLs/util'
import { ILoginData, IUserAuthInfo, IUserBasicInfo, IUserRawSession } from '@/components/User/types/user'

import { Session } from './Session'
import { AuthError } from './AuthError'

// IAM as a builtin app with a constant id
const IAM_APP_ID = 1

const appPermissions: Kv<string[]> = {
  [IAM_APP_ID]: ['0x01', '0x02']
}

// get a merged permission list (@ref aclTables keys) by app ids
const reducePermissions = (appList: string[]): string[] =>
  appList.reduce<string[]>((list, id) => list.concat((appPermissions[id] || []).map(o => String(parseInt(o, 16)))), [])

// normalize authorized user info model
const normalizeUserSession = (raw: IUserRawSession<IUserBasicInfo>): IUserAuthInfo => {
  const user = raw.user
  const administeredApps = raw.administeredApps ?? []
  const permissions: string[] = reducePermissions(administeredApps)

  const userInfo = {
    ...user,
    ownedApps: administeredApps || [],
    permissions,
    featureBlacklist: []
  }

  return userInfo
}

/**
 * User store for vuex state spec
 */
interface S extends IStoreState<Kv, IUserAuthInfo> {
  // user session object
  session: Session,

  captchaImageUrl: string | null;
  indexPath: string;
  aclRoutes: RouteConfig[];

  // cache all of the installed routes
  installedRoutes: RouteConfig[];
}

interface ILoginResult {
  user: IUserAuthInfo;
  redirectURL: string;
  message: string;
  loginSuccess: boolean;
}

const SESSION_KEY = 'CASTGC'

const state: S = {
  session: new Session(),
  captchaImageUrl: null,
  indexPath: '/',
  aclRoutes: [],
  installedRoutes: []
}

const getters: GetterTree<S, RootState> = {
  // Get current session user identify (aka user name by cookie)
  getCurrent: (state: S) => (): Session | null => {
    const sess = state.session
    return sess?.userInfo && sess || null
  },

  // Get current auth login user info
  userInfo: (state: S): IUserAuthInfo | null => state.session.userInfo,

  // Get current auth login user permissions (ACL)
  aclTables: (state: S): ACLTable | null => state.session.acl,

  aclRoutes: (state: S): RouteConfig[] => state.aclRoutes,

  installedRoutes: (state: S): RouteConfig[] => state.installedRoutes,

  checkACL: (state: S, getters) => (path: string): boolean => !!checkACL(path, getters.aclTables),

  // assert blacklist feature
  assertBlackFeature: (state: S) => (name: string, loose?: boolean) => state.session.macc?.assertBlackFeature(name, loose),

  getAppIndex: (state: S, getters, rootState, rootGetters) => (refPath: string = ''): string => state.indexPath,

  findIndexPage: (state: S) => (contextPath: string, args?: Dictionary<'query' | 'params', Kv>) => findFAP(state.aclRoutes, contextPath, args)
}

const mutations: MutationTree<S> = {
  setAuthInfo (state: S, info: IUserAuthInfo | null) {
    state.session.setAuthInfo(info)
  },

  setACL (state: S, permissions: string[] | null) {
    state.session.setACL(permissions)
  },

  setRoutes (state: S, reduced: RouteConfig[]) {
    state.aclRoutes = constRoutes.concat(reduced)
    state.installedRoutes = asyncRoutes

    // update application route entries
    router.setEntries(asyncRoutes)
  },

  setIndexPath (state: S, indexPath: string) {
    state.indexPath = indexPath
  },

  setCaptchaImageUrl (state: S, url: string) {
    state.captchaImageUrl = url
  }
}

const actions: ActionTree<S, RootState> = {
  // 验证码
  async refreshCaptcha ({ state, commit }): Promise<{ captchaImageUrl: string; }> {
    commit('setCaptchaImageUrl', '')
    return Promise.resolve({ captchaImageUrl: '' })
  },

  async resetPassword ({ commit }, params: Record<'newPassword' | 'oldPassword', string>): Promise<boolean> {
    // encrypt password
    params = ['newPassword', 'oldPassword'].reduce((p, k) => (set(p, k, encryptPassword(p[k])), p), { ...params })
    const result = await api.put('/user/password', params)
    return !!result
  },

  // user login
  async login ({ commit, state, getters, dispatch }, { form, query }: ILoginData): Promise<{
    userInfo?: IUserAuthInfo;
    redirectURL?: string;
    captchaImageUrl?: string
  }> {
    const formData = { ...form }
    if (formData.password) {
      formData.password = encryptPassword(formData.password.trim())
    }
    try {
      const {
        loginSuccess,
        jwt,
        user,
        redirectURL,
        administeredApps
      } = await api.post<{
        loginSuccess: boolean;
        jwt?: string;
        user?: IUserBasicInfo;
        redirectURL?: string;
        administeredApps?: number[]
      }>(`/cas/login`, formData)

      if (!loginSuccess || !jwt) {
        throw new Error('User auth info unavailable')
      }

      let userInfo: IUserAuthInfo

      if (user) {
        userInfo = normalizeUserSession({ user, administeredApps })
        await dispatch('setAuthInfo', userInfo)
      } else {
        userInfo = await dispatch('getAuthInfo')
      }

      // reset captcha
      commit('setCaptchaImageUrl', null)

      return { userInfo, redirectURL }
    } catch (e: any) {
      const response = e.data
      if (get(response, 'data.captchaImageUrl')) {
        commit('setCaptchaImageUrl', response.data.captchaImageUrl)
      }
      if (get(response, 'data.code') === 1001) {
        throw new Error('1001')
      } else {
        throw e
      }
    }
  },

  // user logout
  async logout ({ commit, state, dispatch }, params: { service?: string; }): Promise<{ redirectURL?: string; }> {
    const result = await api.get<{ redirectURL?: string; }>(`/cas/logout`, {
      params,
      headers: { accept: 'application/json' }
    })
    await dispatch('clear')
    return result
  },

  // set user auth info (with acl tables)
  async setAuthInfo ({ commit, state, getters, dispatch }, info: IUserAuthInfo) {
    // commit user info
    commit('setAuthInfo', info)

    // update acl
    await dispatch('renewACL', info.permissions)
  },

  // get authorized user info
  async getAuthInfo ({ commit, state, getters, dispatch }, payload?: { renew?: boolean }): Promise<IUserAuthInfo> {
    payload = payload || {}

    let info: IUserAuthInfo = getters.userInfo
    if (info && !payload.renew) {
      return info
    }

    const config = { silent: true }
    let rawInfo: IUserRawSession | null = null
    try {
      rawInfo = await api.get<IUserRawSession>(`/cas/session`, config)
    } catch (e) {
      throw new AuthError(e)
    }

    info = normalizeUserSession(rawInfo)
    if (info.ownedApps.length === 0 && !info.isMaster && payload.checkAuth) {
      throw new Error('Access is denied')
    }

    return info
  },

  async reload ({ commit, state, dispatch }): Promise<IUserAuthInfo> {
    const info: IUserAuthInfo = await dispatch('getAuthInfo', { renew: true })
    commit('setAuthInfo', info)
    return info
  },

  // clear auth info
  clear ({ commit, state, dispatch }) {
    commit('setAuthInfo', null)
    cookie.remove(SESSION_KEY)
    resetRouter()
  },

  /**
   * Dynamically renew user ACL, returns matched route items.
   *
   * @param permissions {string[] | null} The user original permission list
   */
  async renewACL ({ commit, dispatch, getters }, permissions: string[] | null): Promise<RouteConfig[]> {
    resetRouter()

    if (!isArray(permissions)) {
      permissions = []
    }

    // update session acl by permissions (ACL Tables)
    commit('setACL', permissions)

    // generate and update accessible routes map based on ACL
    const aclRoutes = await dispatch('reduceRoutes', getters.aclTables)

    // application ready
    commit('app/setReady', true, { root: true })

    return aclRoutes
  },

  reduceRoutes ({ commit }, acl: ACLTable): Promise<RouteConfig[]> {
    const reduced: RouteConfig[] = reduceRoutes(asyncRoutes, acl)
    commit('setRoutes', reduced)

    const {
      path = '/'
    } = findNodeRef(reduced, (parent, r, path) => !r.hidden && !!r.path.replace(/^\//, '') && !isPureRoute(r)) || {}

    commit('setIndexPath', path)

    return Promise.resolve(reduced)
  }
}

const StoreImpl: Module<S, RootState> = {
  name: 'session',
  state,
  actions,
  mutations,
  getters,
  namespaced: true
}

export default StoreImpl
