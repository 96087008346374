// Make sure to register before importing any components
import { Component } from 'vue-property-decorator'
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN' // element-ui lang

import zhLocale from '../lang/i18n/zh_CN'
import i18n from '../lang'

// Register the router hooks with their names
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate' // for vue-router 2.2+
])

// initial locale
i18n.setLocaleMessage('zh', {
  ...elementZhLocale,
  ...zhLocale
})
