import { isValue, isNumeric } from '@tdio/utils'

import moment from './moment'

type DateFormaShorthand = 'relative' | 'default' | 'YYYY-MM-DD HH:mm:ss' | string

const DEFAULT_DATE_PATTERN: DateFormaShorthand = 'YYYY-MM-DD HH:mm:ss'
const TODAY = $t('Today')
const YESTERDAY = $t('Yesterday')

type DateTime = number | string | Date

function parseDate (dt: DateTime): Date | null {
  let date: Date
  let ts: any = (typeof dt === 'string') ? dt : +dt
  if (typeof dt === 'object') {
    date = dt
  } else {
    if (isNumeric(dt)) {
      ts = parseInt(dt as string, 10)
    }
    if ((typeof dt === 'number') && (dt.toString().length === 10)) {
      ts *= 1000
    }
    date = moment(ts).toDate()
  }
  return date
}

export default function relativeDate (v: DateTime, includeTime?: boolean): string {
  const m = moment.isMoment(v) ? v : moment(v)
  const dt = new Date()
  const mMonth = m.month()
  const mDate = m.date()
  const date = dt.getDate()
  let dateStr = ''
  if (dt.getFullYear() === m.year() && dt.getMonth() === mMonth) {
    const diff = date - mDate
    if (diff === 0) {
      dateStr = TODAY
    } else if (diff === 1) {
      dateStr = YESTERDAY
    }
  }
  if (!dateStr) {
    includeTime = false
    dateStr = m.format(`L`)
  }
  const timeStr = includeTime ? ` ${m.format('HH:mm:ss')}` : ''
  return `${dateStr}${timeStr}`
}

// Format datetime (with relative datetime format supported)
export function formatDate (v: DateTime, format?: DateFormaShorthand): string {
  if (!isValue(v) || !(v = parseDate(v)!) || isNaN(v.valueOf())) {
    return ''
  }

  if (!format || format === 'relative') {
    return relativeDate(v, true)
  }

  if (format === 'default') {
    format = DEFAULT_DATE_PATTERN
  }

  return moment(parseDate(v) as any).format(format)
}

// Format time with time ago
export function timeAgo (time: DateTime, format?: string): DateTime {
  const d = parseDate(time)
  if (!d) {
    return time
  }

  const now = Date.now()
  const diff = (now - +d) / 1000

  if (diff < 30) {
    return '刚刚'
  }
  if (diff < 3600) {
    // less 1 hour
    return `${Math.ceil(diff / 60)}分钟前`
  }
  if (diff < 3600 * 24) {
    return `${Math.ceil(diff / 3600)}小时前`
  }
  if (diff < 3600 * 24 * 2) {
    return '1天前'
  }

  if (format) {
    return formatDate(time, format)
  }

  return `${d.getMonth() + 1}月${d.getDate()}日${d.getHours()}时${d.getMinutes()}分`
}
