import { Component, Mixins, Watch, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { get } from '@tdio/utils'
import { Spiner } from '@tdio/tdui'

import Breadcrumb from '@/components/Breadcrumb'
import { findUp } from '@/router/utils'

import ResizeMixin from './mixin/ResizeHandler'
import {
  SideNav,
  Navbar,
  AppMain
} from './components'
import Logo from './components/Logo.vue'

import './Layout.scss'

const app = namespace('app')
const settings = namespace('settings')

@Component({
  name: 'Layout',
  mixins: [ResizeMixin],
  components: {
    Logo,
    SideNav,
    Navbar,
    AppMain
  }
})
export default class Layout extends Vue {
  static isLayout = true;

  @app.State('sidebar')
  sidebar!: any;

  @app.State('ready')
  ready!: boolean;

  @settings.State('showSettings')
  showSettings!: boolean;

  @settings.State('fixedHeader')
  fixedHeader!: boolean;

  @settings.State('uiEmbed')
  uiEmbed!: boolean;

  @Watch('ready', { immediate: true })
  handleReady (v) {
    if (v) {
      Spiner.stop()
    } else {
      Spiner.start()
    }
  }

  get hideSideMenu () {
    const arr = this.$route.matched
    if (!arr.length) {
      return true
    }
    return findUp(arr[arr.length - 1], r => get(r, 'meta.hideSideMenu') === true) != null
  }

  render (h: any) {
    const {
      ready,
      hideSideMenu,
      sidebar: {
        opened,
        withoutAnimation
      },
      uiEmbed,
      $scopedSlots
    } = this

    const layoutClass = {
      hide: !ready,
      hideSidebar: !opened,
      openSidebar: opened,
      withoutAnimation
    }

    const showLogo = this.$store.state.settings.sidebarLogo
    const darkMode = this.$store.state.settings.darkMode
    const isCollapse = !opened

    const sidebarWidth = !opened ? '60px' : '160px'
    const sidebarClass = {
      'has-logo': showLogo,
      'is-collapse': isCollapse
    }

    return ready ? (
      <el-container class={['app-container', layoutClass]} direction="vertical">
        {!uiEmbed && (
          <Navbar height="50px">
            { showLogo && (
              <router-link to="/">
                <Logo collapse={isCollapse} dark-mode={darkMode} class="fl" style={{ minWidth: sidebarWidth }} />
              </router-link>
            )}
          </Navbar>
        )}
        <el-container direction="horizontal" class="app-body">
          {!uiEmbed && !hideSideMenu && (
            <el-aside width={sidebarWidth} class={['app-aside', sidebarClass]}>
              { $scopedSlots.sidebar ? $scopedSlots.sidebar() : (<SideNav />) }
            </el-aside>
          )}
          <el-container direction="vertical">
            <Breadcrumb showRoot={!uiEmbed} class="app-breadcrumb" />
            <AppMain />
          </el-container>
        </el-container>
      </el-container>
    ) : null
  }
}
