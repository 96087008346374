/**
 * Feature access controller based on blacklist
 */

type FeatureItemName = string

const featureList: string[] = [
]

const featureListDic = featureList.reduce((p, k) => (p[k] = true, p), {})

const blackPathMap: Kv<FeatureItemName> = {
}

export interface ModuleAccessor {
  assertBlackFeature (key: FeatureItemName, loose?: boolean): boolean;
}

export const initModuleAccessor = (blacklist: FeatureItemName[]): ModuleAccessor => {
  const indexes: Map<FeatureItemName, boolean> = blacklist.reduce((dic, k) => {
    dic.set(k, true)
    return dic
  }, new Map())

  // assert and return true if target in blacklist
  const assert = (path: string, loose?: boolean): boolean => {
    if (!path) {
      throw new Error('Path are required')
    }

    let k = featureListDic[path] && path
    if (!k && loose) {
      k = Object.keys(blackPathMap).find(a => path.startsWith(a))
      if (k) {
        k = blackPathMap[k]
      }
    }

    return k && !!indexes.get(k)
  }

  return {
    assertBlackFeature (key: FeatureItemName, loose = false): boolean {
      return assert(key, loose)
    }
  }
}
