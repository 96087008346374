/* eslint no-control-regex: off */
/* eslint no-useless-escape: off */

export const reInteger = /^[+]{0,1}(\d+)$/

export const rePhone = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/

export const reEmail = /^([\w-.]+)@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(\]?)$/

export const rePort = '请输入1~65535之间的数字'

export const reAbsolutePath = /^\/\w?/

export const reRelativePath = /^[^\/]\w?/

export const reFileName = ''

export const reNoChinese = /[^\x00-\xff]/gi

export const reUrl = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[\w-.]+|(?:www.|[-;:&=+$,\w]+@)[\w-.]+)((?:\/[+~%\/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/

export const reUsername = /^[-\w]{3,20}$/

export const rePostername = /^[\w\u4e00-\u9fa5][-\w\u4e00-\u9fa5]{1,20}$/

export const rePassword = /(?=.*\d)(?=.*\D)^.{6,20}$/

export const reRealName = /^[-\w\u4e00-\u9fa5]{2,20}$/

export const reRoleName = /^[-\w\u4e00-\u9fa5]{2,30}$/

export const reTenantName = /^[\w\u4e00-\u9fa5][-\w\u4e00-\u9fa5]{1,49}$/

export const reServiceName = /^[a-z][-a-z\d]{0,45}$/

export const reContainerName = /^[a-z][-a-z\d]{0,62}$/

export const reServiceNodePort = '请输入12000~65535之间的数字'

export const reEnvName = /^[-_.a-zA-Z][-_.a-zA-Z0-9]*$/

export const reStackName = /^[a-z][-a-z\d]{0,35}$/

export const reStackTemplateName = /^[a-z][-a-z\d]{0,35}$/

export const reStackTemplateVersion = /^[\w][\w-.]{0,63}$/

export const reConfigGroupName = /^[a-z]([a-z\d-]*)$/

export const reStackTemplateFileName = /^[\w-.]{1,36}$/

export const reConfigGroupFileName = /^[\w-.]{1,64}$/

export const reLoadBalancerName = /^[a-z][-a-z\d]{0,35}$/

export const reDomain = /^([a-z\d]([\w-]{0,61}[a-z\d])?\.)+[a-z]{2,6}$/i

export const reDomainRecord = /^[a-z\d]([a-z\d-]{0,61}[a-z\d])?(\.[a-z\d]([a-z\d-]{0,61}[a-z\d])?)?$/

export const reImageName = /^[a-z\d-_]([a-z\d-_.]{0,23}[a-z\d-_])?$/

export const reImageVersion = /^\w[\w-.]{0,24}$/

export const reLibraryName = /^[a-z][a-z\d-]{0,19}$/

export const reCicdName = /^[a-zA-Z]([\w-:.])*$/

export const reNetworkName = /^[\w-.]{1,63}$/

export const rePolicyName = /^[a-z\d]([a-z\d-.]*[a-z\d])?$/

export const reIp = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])(\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])){3}$/

export const reNetwork = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])(\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])){3}$/

export const reSubnetMask = /^(254|252|248|240|224|192|128|0)\.0\.0\.0|255\.(254|252|248|240|224|192|128|0)\.0\.0|255\.255\.(254|252|248|240|224|192|128|0)\.0|255\.255\.255\.(254|252|248|240|224|192|128|0)$/

export const reGateway = ''

export const reVlanId = '必须是1-4094区间内的整数'

export const reInterface = /^[a-z][.\w]{0,15}$/i

export const reClusterName = /^[a-z][a-z\d-]{1,19}$/

export const reVolumeName = /^[a-z][a-z\d-]{0,45}$/

export const reMonitorName = /^[\w\u4e00-\u9fa5]{1,20}$/

export const reWebsiteMonitorName = /^[\w\u4e00-\u9fa5]{1,20}$/

export const reTemplateName = /^[\w\u4e00-\u9fa5]{1,50}$/

export const reMonitorTemplateName = /^[\w\u4e00-\u9fa5]{1,20}$/

export const reAlarmRuleName = /^[\w\u4e00-\u9fa5]{1,20}$/

export const reContactGroupName = /^[\w.\u4e00-\u9fa5]{1,32}$/

export const reCertName = /^[a-z][-a-z\d]{0,39}$/

export const reMicroServiceName = /^[a-z][-a-z\d]{0,45}$/

export const reMicroServiceGatewayName = /^[a-z][-a-z\d]{0,45}$/

export const reMicroServiceGroupName = /^[a-z]([a-z\d-]{0,20})$/

export const reServiceVersion = /^[a-z\d]([a-z\d-]*[a-z\d])?$/

export const reTagName = /^[\S]{1,32}$/

export const reTagValue = /^[\S]{1,32}$/

export const regPortValue = /^([1-9]\d{0,3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/

export const reIPv6 = /^[\da-fA-F]{4}$/

// 是否为梯度平台部署的namespace
export const regNamespace = /^[\d]+(--)[\d]+$/

export const reHostnamePrefix = /^[a-z\d][a-z\d-]*$/

// export const reAnnotationKey = /^([a-zA-Z0-9.-]{1,150}\/)?((?=[a-zA-Z0-9])[a-zA-Z0-9-_.]{1,50}(?<=[a-zA-Z0-9]))$/

export const reAnnotationKey = /^([a-zA-Z0-9.-]{1,150}\/)?([a-zA-Z0-9][a-zA-Z0-9-_.]{0,48})?[a-zA-Z0-9]$/

export const reVMName = /^[a-z][-a-z\d]{0,45}$/

export const reVMDeviceName = /^[a-z][-a-z\d]{0,45}$/

export const reMax255 = /^.{1,255}$/
