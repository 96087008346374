import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { get, resolveUrl } from '@tdio/utils'
import { traverse } from 'route-utils'

import { isExternal } from '@/utils/validate'
import { RouteType, findTopPath } from '@/router/utils'
import { Item } from '@/components/RouteMenu'

import './Topnav.scss'

@Component({
  components: { Item }
})
export default class Topnav extends Vue {
  @Prop({ type: String, default: '/' })
  basePath!: string

  get navItems (): RouteConfig[] {
    const routerList: RouteConfig[] = this.$store.getters['session/aclRoutes']
    const root = this.basePath
    const items: RouteConfig[] = []
    traverse(routerList, {
      enter (parent, node, path) {
        if (!path.startsWith(root)) {
          return -1
        }
        if (get(node, 'meta.type') === RouteType.Top) {
          if (!node.hidden) {
            items.push(node)
          }
          return -1
        }
        return 0
      }
    })
    return items
  }

  resolveUrl (routePath: string) {
    if (isExternal(routePath)) {
      return routePath
    }
    return resolveUrl(this.basePath, routePath, true)
  }

  render (h: any) {
    const { path, matched } = this.$route
    const defaultActive = findTopPath(matched, path)

    return (
      <el-menu
        mode="horizontal"
        class="app-topnav"
        defaultActive={defaultActive}
      >
        {
          this.navItems.filter(r => !!r.meta).map(r => {
            const meta = r.meta
            const key = this.resolveUrl(r.path)
            return (
              <el-menu-item key={key} index={key}>
                <Item to={key} icon={meta.icon} title={$t(meta.title || meta.name)} />
              </el-menu-item>
            )
          })
        }
      </el-menu>
    )
  }
}
