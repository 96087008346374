import { LispTree, transformLispTree, RuleMatcher, normalizeCondition } from '@tdio/rule-set'

import getConfig from '@/config/getConfig'
import store from '@/store'

const { whitelist, blacklist } = getConfig<Record<'whitelist' | 'blacklist', LispTree>>('menuConfig.rules', {})

const conditions: Kv<RuleMatcher> = {}

if (whitelist) {
  const wlObj = transformLispTree(whitelist)
  if (wlObj.size() > 0) {
    conditions.whitelist = s => wlObj.test(s, true)
  }
}

if (blacklist) {
  const blObj = transformLispTree(blacklist)
  conditions.blacklist = s => blObj.test(s)
}

conditions.exclude = s => store.getters['session/assertBlackFeature'](s, true)

export const matchModuleBWL: RuleMatcher = normalizeCondition(conditions)
