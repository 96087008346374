/* eslint-disable no-underscore-dangle */

import Vue, { VueConstructor } from 'vue'
import { get, isNil, isValue, isNumber } from '@tdio/utils'

import store from '@/store'
import { IUserAuthInfo } from '@/components/User/types/user'
import { UserType } from '@/components/User/models/user'

const isDef = (v: any) => !isNil(v)

type ContextParams = Kv & {
  rootPath: string; // Application router base prefix (based on user type)
  topLevelPrefix: string; // Top level module base path (prefix)
  userInfo?: IUserAuthInfo; // Current login user info
}

export class Context {
  app!: Vue | null

  params: ContextParams = {
    rootPath: '',
    topLevelPrefix: '/'
  }

  rootPath: string = '/'
  install: any

  /** @constructor */
  constructor (app?: Vue) {
    if (app) {
      this.app = app
    }
  }

  init (app: Vue) {
    this.app = app

    app.$store.watch(
      () => [
        store.getters['session/userInfo']
      ],
      ([userInfo]) => {
        this.params = {
          ...this.params,
          userInfo
        }
      }
    )
  }

  /**
   * Get current auth login user info
   *
   * @returns {IUserAuthInfo}
   */
  get userInfo (): IUserAuthInfo {
    return this.get<IUserAuthInfo>('userInfo')
  }

  /**
   * Get current auth user type (UserType.Tenant | UserType.Platform)
   *
   * @returns {UserType}
   */
  get userType (): UserType {
    return this.get<UserType>('userInfo.userType')
  }

  // check current user is tenant usertype
  get isTenantUserType (): boolean {
    return this.userType === UserType.Tenant
  }

  // check current user is platform usertype
  get isPlatformUserType (): boolean {
    return this.userType === UserType.Platform
  }

  /**
   * Generic api for retrieve param by a specific key
   *
   * @param {string} path The parameter key path (namespace supported)
   */
  get <TValue = any> (path: string, defaultVal?: TValue) {
    return get<TValue>(this.params, path, defaultVal)
  }

  // Helper for router to a module by specific route id
  redirect (moduleId: string, params?: any) {
    const $router = this.app!.$router
    const curr = $router.currentRoute.path
    const to = $router.genModuleEntryUrl(moduleId, params)
    if (to !== curr) {
      $router.replace(to)
    }
  }

  private getTopLevelPrefix (): string {
    const path = this.app!.$route.path
    return ['', ...path.split('/').filter(Boolean).slice(0, 2)].join('/')
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $context: Context;
    getv <T = any> (o: any | Vue, path?: string | T, defaultVal?: T): T;
  }
}

let _Vue: VueConstructor

const install: any = (Vue: VueConstructor, options: any) => {
  if ((install).installed && _Vue === Vue) {
    return
  }
  install.installed = true

  _Vue = Vue

  const registerInstance = (vm: any, callVal?: any) => {
    let i = vm.$options._parentVnode
    if (isDef(i) && isDef(i = i.data) && isDef(i = i.registerContextInstance)) {
      i(vm, callVal)
    }
  }

  Vue.mixin({
    beforeCreate (this: any) {
      if (isDef(this.$options.context)) {
        this._contextRoot = this
        this._context = this.$options.context
        this._context.init(this)
      } else {
        this._contextRoot = (this.$parent && this.$parent._contextRoot) || this
      }

      registerInstance(this, this)
    },
    destroyed () {
      registerInstance(this)
    },
    // Extends with some common utilities
    methods: {
      getv <T = any> (o: any, path?: string, defaultVal?: T): T {
        if (o && typeof o === 'string') {
          defaultVal = path as unknown as T
          path = o
          o = this
        }
        return get(o, path!, defaultVal) as T
      }
    }
  })

  // Extends Vue prototype
  Object.defineProperty(Vue.prototype, '$context', {
    get () { return (this._contextRoot || this.$root._contextRoot)._context }
  })
}

const context = new Context()

context.install = install

// install
Vue.use(context)

export default context
