/* eslint-disable import/order */

import 'core-js/actual/object'
import Vue, { CreateElement, VueConstructor } from 'vue'
import { Renderer } from '@allex/vue-renderer'
import { debounce, get, set } from '@tdio/utils'

// Make sure called before importing any components
import './config/preInit'

import i18n from './lang'

import ElementUI, { Message } from 'element-ui'

// import base styles first include reset, element-ui, etcs,.
import './styles/base.scss'

import {
  Button, ButtonGroup,
  AsyncSelect, Select, TreeSelect,
  Literal, ListLabel, RichText,
  ViewLoader
} from '@tdio/tdui'

// application-level common styles
import './styles/index.scss'

// element patches
import { patchComponents } from './utils/patches/elementUI'

// icons
import './assets/icons'

// Extends Vue instance with application-level context ASAP
import context from './utils/context'

import * as filters from './utils/filters' // global filters
import http from './utils/http'

import store from './store'
import router, { toLogin, initRouter } from './router'

import permission from './components/ACLs/directive'
import { Search } from './components/Search'

import App from './App'

http.setup({
  headers: {
  },
  transform ({ data: raw }) {
    let { data, code, message, errors, err, msg } = raw
    if (err !== undefined) {
      code = code || err
    }
    if (msg !== undefined) {
      message = message || msg
    }
    data = data || raw
    // handle graphql error spec
    if (errors?.length > 0) {
      code = 1
    }
    return { code, data, message }
  },
  errorHandle: debounce((err: Error & { code: number }) => {
    const { message, code } = err
    switch (code) {
      case 401:
        // session timeout (is logged-in?)
        store.dispatch('session/clear').finally(toLogin)
        break
      case 403:
        // permission, authorized?
        Message({
          type: 'error', message: $t(message || 'No Authenticated'), showClose: true, duration: 2000
        })
        break
      default:
        if (get<boolean>(err, 'config.silent', false) !== true) {
          Message({
            type: 'error', message: $t(message), duration: 6000, showClose: false
          })
        }
        break
    }
  }, 100)
})

// bootstrap
function bootstrap (Vue: VueConstructor) {
  // component globally configure
  Vue.config.productionTip = false

  // register filters.
  Object.keys(filters).forEach(k => Vue.filter(k, (filters as Kv)[k]))

  // register global components
  const components: Kv = {
    Renderer,
    Literal,
    ListLabel,
    RichText,
    Button,
    ButtonGroup,
    AsyncSelect,
    Select,
    TreeSelect,
    ViewLoader,
    Search
  }
  Object.keys(components).forEach(k => Vue.component(k, components[k]))

  // init router with permission control inteceptor
  initRouter()

  // Add patches eleui, defaults. (MUST BE BEFORE COMPONENTS INSTALLATION)
  patchComponents(ElementUI)

  // components defaults patch
  set(Select.superOptions, 'props.defaultFirstOption.default', false)

  // install element-ui
  Vue.use(ElementUI, {
    size: 'small',
    i18n: (key: string, value: any) => i18n.t(key, value),
    zIndex: 100,
    message: {
      duration: 5000,
      dangerouslyUseHTMLString: true
    },
    tooltip: {
      tabindex: -1,
      dangerouslyUseHTMLString: true
    }
  })

  // install permission directive
  Vue.use(permission)

  new Vue({
    context,
    router,
    store,
    i18n,
    render: (h: CreateElement) => <App />
  }).$mount('#root')
}

bootstrap(Vue)
