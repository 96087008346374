import Vue, { CreateElement, VNode } from 'vue'
import { Component } from 'vue-property-decorator'

@Component
export default class PureRouterView extends Vue {
  static isPureView = true;
  render (h: CreateElement): VNode {
    return (
      <router-view />
    )
  }
}
