import VueRouter, { Route, RouteRecord } from 'vue-router'
import { omitBy } from 'lodash'
import { deepAssign, isFunction, isEmpty, memoize, get } from '@tdio/utils'
import window from '@allex/global'
import {
  findNodeRef, ResolveRouteOptions, resolveRoutePath, matchPath, EMatch
} from 'route-utils'

import { RouteType, RouteConfig, GenericLocation } from '../types'

export { RouteType }

type IParamMapper = (o: any) => Kv

interface IPreDefineModuleOptions {
  paramer: IParamMapper;
  key?: string;
  path?: string;
}

const isAbsolutePath = (path: string) => /^https?:\/\//i.test(path)

// cache application async route entries
let asyncRoutes: RouteConfig[] = []

// API for entries injection
export const setEntries = (entries: RouteConfig[]) => asyncRoutes = entries

const moduleUrls: Kv = {}

// module url prefix cache
const getRoutePathByGlobalKey = memoize((key: string, rootPath: string = '/'): string => {
  const context = findNodeRef(asyncRoutes, (parent, r, path) => get(r, 'meta.routeKey') === key, { prefix: rootPath })
  if (context) {
    return context.path
  }
  return ''
}, (...args: string[]) => args.join(','))

type LocationArgs = Record<'params' | 'query', Kv>

const normalizeLocation = (params: Kv, query?: Kv): LocationArgs => {
  const args: LocationArgs = { params: {}, query: {} }
  if (params) {
    // Normalize and archive the complex params pairs:
    // { params, query, foo, baz, ... } => { params, query }
    const reserveKeys = ['params', 'query']
    if (reserveKeys.some(k => params[k] !== undefined)) {
      Object.keys(params)
        .filter(k => !isEmpty(params[k]))
        .forEach(k => {
          const v = params[k]
          if (reserveKeys.includes(k)) {
            deepAssign(args[k], v)
          } else {
            args.params[k] = v
          }
        })
    } else {
      args.params = { ...params }
    }
  }
  if (query) {
    deepAssign(args.query, query)
  }
  return args
}

export function genModuleEntryUrl (this: VueRouter, key: string, params?: any, query?: Kv, rootPath?: string): string {
  const app = this.app

  rootPath = rootPath || app.$context.rootPath

  // Evalute route with params (mixins with current route params)
  const opts: ResolveRouteOptions = {
    base: rootPath,
    query: { ...query },
    params: { ...app.$route.params }
  }

  const spec: IPreDefineModuleOptions | undefined = moduleUrls[key]

  // Optional mixin pre define specs.
  const path = getRoutePathByGlobalKey(spec && spec.key || key, rootPath) || (spec && spec.path)
  if (!path) {
    throw new Error(`Invalid module entry '${key}'`)
  }

  const locationArgs = normalizeLocation(params, query)
  deepAssign(
    opts,
    locationArgs,
    spec
      ? normalizeLocation(spec.paramer(locationArgs.params))
      : null
  )

  // Returns the sanitized query
  opts.query = omitBy(opts.query, isEmpty)

  return resolveRoutePath(path, opts)
}

export function open (this: VueRouter, path: GenericLocation | string) {
  let location: GenericLocation = {}
  let realpath = ''

  if (typeof path === 'string') {
    if (isAbsolutePath(path)) {
      realpath = path
      location.path = path
      location.external = true
    } else {
      location.path = path
    }
  } else {
    location = path
    if (location.path) {
      realpath = location.path
    }
  }

  // path as `meta.globalKey`
  if (location.key) {
    realpath = this.genModuleEntryUrl(location.key, location.params, location.query)
    if (realpath) location.path = realpath
  }

  if (realpath) {
    if (location.external === undefined && isAbsolutePath(realpath)) {
      location.external = true
    }
    if (location.external) {
      if (location.replace) {
        window.location.replace(realpath)
      } else if (location.newopen) {
        window.open(realpath)
      } else {
        window.location.href = realpath
      }
      return
    }
  }

  this.push(location)
}

export const isPureRoute = (r: Partial<RouteConfig>): boolean => {
  const c = get(r, 'component')
  return c && isFunction(c) && c.isPureView === true
}

export const findTopPath = (routes: RouteConfig[], prefix: string): string => {
  const {
    path = `/${prefix.split('/').filter(Boolean)[0]}`
  } = findNodeRef(routes, (parent, r, path) => (get(r, 'meta.type') === RouteType.Top), { prefix, bfs: true }) || {}
  return path
}

export const isAvailableView = (c: VueComponentDefinition | void): boolean => !!c
  && isFunction(c)
  && !(c.isPureView === true || c.isLayout === true)

export const isRouteViewLoaded = (route: Route) =>
  route.matched.some(o => isAvailableView(o.instances?.default?.constructor as VueComponentDefinition))

/**
 * Find the first available page, with a root context path optionally
 */
export const findFAP = (routes: RouteConfig[], root: string, args?: Dictionary<'query' | 'params', Kv>) => {
  const {
    path = '/'
  } = findNodeRef(routes, (parent, r, path) => {
    const m = matchPath(path, root)
    if (m === EMatch.NE) {
      return -1 // SKIP
    }
    if (m === EMatch.LT) { // LT
      return false
    }
    return !r.hidden && !r.isLayout && isAvailableView(get<VueComponentDefinition>(r, 'component'))
  }) || {}
  return args ? resolveRoutePath(path, args) : path
}

/**
 * Reduce route items (resolve node path, flatten shadow nodes)
 */
export const reduceRouteItems = (items: RouteConfig[], base?: string): RouteConfig[] => items.reduce<RouteConfig[]>((p, r) => {
  r = { ...r } // shallow copy
  if (base) {
    r.path = `${base.replace(/[/]+$/, '')}/${r.path}`
  }
  if (!r.children || r.children.length === 0) {
    p.push(r)
  } else if (r.shadow) {
    p = p.concat(reduceRouteItems(r.children, r.path))
  } else {
    r.children = reduceRouteItems(r.children)
    p.push(r)
  }
  return p
}, [])

export const findUp = (route: RouteRecord, predicate: (route: RouteRecord) => boolean) => {
  let r: RouteRecord | undefined = route
  while (r && !predicate(r)) {
    r = r.parent
  }
  return r
}
