const loginLocales = {
  Login: '登录',
  Logout: '退出登录',
  LoginName: '用户名',
  Password: '密码',
  LoginNameError: 'Please enter the correct user name',
  LoginPasswordError: 'The password can not be less than 6 digits',
  'Forget Password': '忘记密码',
  'Please contact the administrator to reset your password.': '请联系管理员重置密码。'
}

export default loginLocales
