// Icon
import Vue from 'vue'
import { Icon } from '@tdio/tdui'

// register globally <Icon :icon-name="$propName" class="i" />
Vue.component('Icon', Icon)

// Imports all svg icons
const requireAll = (requireContext: any) => requireContext.keys().map(requireContext)

requireAll(require.context('./svg', false, /\.svg$/))
