import PureRouterView from '@/components/common/PureRouterView'

export const posterManage = {
  path: 'poster',
  meta: { name: 'posterManage', icon: 'el-icon-bank-card' },
  component: PureRouterView,
  children: [
    {
      path: '',
      component: () => import('@/views/workbench/poster/index.vue'),
      meta: { title: '海报管理' }
    }
  ]
}

export const articleManage = {
  path: 'article',
  meta: { name: 'article', icon: 'el-icon-document-copy' },
  component: PureRouterView,
  children: [
    {
      path: '',
      component: () => import('@/views/workbench/article/index.vue'),
      meta: { title: '文档管理' }
    },
    {
      path: 'detail/:id',
      hidden: true,
      props: route => ({ articleId: route.params.id }),
      component: () => import('@/views/workbench/article/detail.vue'),
      meta: { title: '文档详情', routeKey: 'topicDetail' }
    }
  ]
}

export const articleCategory = {
  path: 'category',
  meta: { name: 'category', icon: 'el-icon-folder-checked' },
  component: PureRouterView,
  children: [
    {
      path: '',
      component: () => import('@/views/workbench/article/category.vue'),
      meta: { title: '栏目管理' }
    }
  ]
}
