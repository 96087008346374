import { Message } from 'element-ui'
import Vue, { VueConstructor } from 'vue'
import VueRouter, { NavigationGuardNext, Route } from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { get, isEmpty, isValue } from '@tdio/utils'
import { Spiner } from '@tdio/tdui'

import getPrefs from '@/config/getConfig'
import { IUserAuthInfo } from '@/components/User/types/user'

import store from '../store'

import { LOGIN_SERVICE } from './config'
import { isAvailableView, isRouteViewLoaded } from './utils'

const encode = encodeURIComponent

const getRouteTitle = (route: Route): string => route.matched.reduceRight((title, r) => {
  const meta = r.meta
  return title || (meta && (meta.title || meta.name))
}, '')

const processRouteBegin = (target: Route, next: NavigationGuardNext<Vue>) => {
  const { params, query, matched = [] } = target
  const lastMatch = matched[matched.length - 1]
  const routeVi = get(lastMatch, 'components.default')
  const targetPath = lastMatch.path
  if (targetPath !== '/' && !isAvailableView(routeVi)) {
    const firstPage = store.getters['session/findIndexPage'](targetPath, { params, query })
    if (firstPage && targetPath !== firstPage) {
      next({ path: firstPage, replace: true })
      Spiner.stop()
      return
    }
  }
  next()
}

export default function initRouter (router: VueRouter): void {
  NProgress.configure({ showSpinner: false }) // NProgress Configuration

  const isMatched = (p: string) => router.matcher.match(p).matched.length > 0

  // no redirect whitelist
  const whiteList = [LOGIN_SERVICE, '/auth-redirect', '/403', '/404']

  router.beforeEach(async (to, _from, next) => {
    const { path: toPath, fullPath, matched } = to
    const returnUrl = encode(fullPath)

    if (!isRouteViewLoaded(to)) {
      Spiner.start()
    }

    const title = getRouteTitle(to)
    document.title = `${title ? `${$t(title)} - ` : ''}${getPrefs('sys.appName')}`

    if (whiteList.some(p => toPath.startsWith(p))) {
      // in the free login whitelist, go directly
      next()
    } else {
      let auth: IUserAuthInfo | null = null

      // determine whether the user has logged in
      try {
        auth = await store.dispatch('session/getAuthInfo')
      } catch (error: any) {
        const loginService = error.data?.redirectURL || LOGIN_SERVICE

        // cleanup session remnants
        await store.dispatch('session/clear')

        console.error(`Get authorization failed, redirect to => ${loginService} ...`)

        if (isMatched(loginService)) {
          next({ path: loginService, query: { redirect: returnUrl } })
        } else {
          // in case login service is an 3rd sso service
          const query = `redirect=${encode(location.href)}`
          location.replace(`${loginService}${loginService.includes('?') ? '&' : '?'}${query}`)
        }
        return
      }

      if (auth) {
        const aclTables = store.getters['session/aclTables']
        if (isValue(aclTables)) {
          // check acl
          if (isEmpty(aclTables)) {
            // remove token and go to login page to re-login
            await store.dispatch('session/clear')
            Message.error($t('Access is denied'))
          } else if (matched.length && toPath !== '/') {
            const authKey = matched[matched.length - 1].path
            if (!authKey || store.getters['session/checkACL'](authKey)) {
              processRouteBegin(to, next)
            } else {
              console.error(`Error: Please check the resouce permission: ${authKey}. (Access denied)`)
              next({ path: '/403', query: { redirect: returnUrl }, replace: true })
            }
          } else {
            // Try to redirect to the first accessible path
            const indexPage = store.getters['session/getAppIndex'](toPath)
            if (indexPage === '/') {
              Message.error('该用户没有权限访问，请联系管理员！')
              Spiner.stop()
            } else if (indexPage === toPath) {
              next()
            } else {
              next({ path: indexPage || '/404', replace: true })
            }
          }
        } else {
          // hack method to ensure that app routes is reduced
          await store.dispatch('session/setAuthInfo', auth)
          next({ ...(to as any), replace: true })
        }
      } else {
        // other pages that do not have permission to access are redirected to the login page.
        next({ path: LOGIN_SERVICE, query: { redirect: returnUrl } })
      }
    }
  })

  router.afterEach(() => {
    Vue.nextTick(() => Spiner.stop())
  })

  router.onError((e: Error) => {
    Spiner.stop()
  })
}
