import appLogo from '../../public/img/logo.svg'
// import appBrandImage from '../../public/img/logo_text.svg'
// import appBrandImageLight from '../../public/img/logo_text_light.svg'
import loginLogoPath from '../../public/img/tidu_logo_text_white.svg'

export interface SystemConfig {
  appName: string;
  appLogo: string;
  appBrandImage: string;
  appBrandImageLight: string;
  copyright: string;
  loginLogoPath: string;
  loginSign: string;
}

const sysConfig: SystemConfig = {
  appName: process.env.APP_NAME,
  appLogo,
  appBrandImage: '',
  appBrandImageLight: '',
  copyright: '© 梯度科技',
  loginLogoPath,
  loginSign: process.env.APP_LOGIN_SIGN || '© 梯度科技'
}

export default {
  /**
   * Whether show the settings right-panel
   * @type {boolean} true | false
   */
  showSettings: false,

  /**
   * Whether fix the header
   * @type {boolean} true | false
   */
  fixedHeader: true,

  /**
   * Whether show the logo in sidebar
   * @type {boolean} true | false
   */
  sidebarLogo: true,

  darkMode: true,

  /**
   * Layout with ui embedded mode (aka hide topnav and sidebar)
   */
  uiEmbed: false,

  /**
   * Force backend integration env for development only.
   * @type {boolean}
   */
  userBackendService: true,

  /**
   * Global config for system customize
   * @type {SystemConfig}
   */
  sys: sysConfig,

  /**
   * Customize auth config for 3rd-party integration
   */
  authConfig: {
    authType: process.env.APP_AUTH_TYPE || 'default', // default, jwt, sso, token, token/zzbz, token/big-data-frame
    tokenName: process.env.APP_AUTH_TOKEN_NAME || 'token'
  },

  /**
   * Init and setup menu items by white_list and black_list mechanism. (Use for OEM distributes)
   *
   * ```
   * menuConfig: {
   *   rules: {
   *     whitelist: ['/',
   *       ['monitor'],
   *       ['logCenter'],
   *       ['tenantCenter'],
   *       ['system']
   *     ],
   *     blacklist: ['/',
   *       ['monitor', ['platform']],
   *       ['logCenter', ['servicelog']],
   *       ['system', ['tag'], ['certificate']]
   *     ]
   *   },
   * }
   * ```
   */
  menuConfig: {
  },

  /**
   * Customize module, initial configs with distributes setting.
   *
   * ```
   * moduleConfig: {
   *   monitor: {
   *     dashboard: {
   *       modules: ['ResourceStatistics', 'ResourceAlert', 'ResourceUsage', 'WebsiteMonitor']
   *     }
   *   }
   * }
   * ```
   */
  moduleConfig: {
  }
}
