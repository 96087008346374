import PureRouterView from '@/components/common/PureRouterView'

export const memberManage = {
  path: 'member',
  meta: { name: 'member', icon: 'menu-user-manage.svg' },
  component: PureRouterView,
  children: [
    {
      path: '',
      component: () => import('@/views/system/member/index.vue'),
      meta: { title: '用户管理' }
    }
  ]
}

export const approvalManage = {
  path: 'approval',
  meta: { name: 'approval', icon: 'el-icon-notebook-2' },
  component: PureRouterView,
  children: [
    {
      path: '',
      component: () => import('@/views/system/member/approval.vue'),
      meta: { title: '审批管理' }
    }
  ]
}
