import Vue, { CreateElement, VNode } from 'vue'
import { RouteConfig } from 'vue-router'
import { Component, Watch } from 'vue-property-decorator'
import { registerModules } from '@tdio/vue-utils'

import store from '@/store'
import session from '@/store/session'

registerModules([session], store)

@Component
export default class App extends Vue {
  @Watch('$route')
  handleRoute (to: RouteConfig) {
  }

  render (h: CreateElement): VNode {
    return (
      <div id="app"><router-view /></div>
    )
  }
}
