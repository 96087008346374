import Vue, { VNode, VueConstructor } from 'vue'
import { DirectiveBinding } from 'vue/types/options'
import { isEmpty, isArray, isBoolean, get } from '@tdio/utils'

import store from '@/store'

import { checkACL as checkACLInter, ACLTable, PermissionVal } from './util'

const getContextUserAclTables = (): ACLTable | null => store.getters['session/aclTables']
const isAdmin = (): boolean => get(store.getters['session/userInfo'], 'isAdmin', false)

export const checkACL = (path: string | string[], strict = true) => {
  const targets = isArray(path) ? path : [path]
  if (!isEmpty(targets)) {
    let hasPermission: boolean = true
    const aclTables: ACLTable | null = getContextUserAclTables()
    if (!aclTables) {
      return false
    }
    targets.forEach((v: any) => {
      hasPermission = hasPermission && checkACLInter(v, aclTables, strict) !== PermissionVal.False
    })
    return hasPermission
  }
  return false
}

const directiveGenerator = (predicate: (code: string) => boolean, usageMsg: string) => ({
  inserted (el: Element, binding: DirectiveBinding, vnode: VNode) {
    const { value } = binding
    if (!isEmpty(value)) {
      const valid = predicate(value)
      if (!valid) {
        if (el.parentNode) {
          el.parentNode.removeChild(el)
        }
      }
    } else {
      throw new Error(usageMsg)
    }
  }
})

export default function (Vue: VueConstructor) {
  // v-admin
  Vue.directive('admin', directiveGenerator(
    v => v && isAdmin(),
    `invalid v-admin, eg. v-admin="true"`
  ))

  // v-acl
  Vue.directive('acl', directiveGenerator(
    v => checkACL(v),
    `invalid v-acl, eg. v-acl="['path/act/del','foo/form/delete']"`
  ))

  // v-acl-loose
  Vue.directive('acl-loose', directiveGenerator(
    v => checkACL(v, false),
    `invalid v-acl-loose, eg. v-acl-loose="['path/act/xxx']"`
  ))
}
