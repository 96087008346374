import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { isEmpty } from '@tdio/utils'
import { findNodeRef } from 'route-utils'

import { findTopPath, reduceRouteItems } from '@/router/utils'
import { RouteMenu } from '@/components/RouteMenu'

@Component({
  components: {
    RouteMenu
  }
})
export default class SideNav extends Vue {
  @Prop({ type: Function, default: o => o })
  routeReduce!: (routes: RouteConfig[]) => RouteConfig[];

  get basePath (): string {
    const { path, matched } = this.$route
    return findTopPath(matched || [], path)
  }

  getRouteItems (): RouteConfig[] {
    const allRoutes = this.$store.getters['session/aclRoutes']
    const context = findNodeRef(allRoutes, this.basePath)
    const items = context && context.node.children || [] as RouteConfig[]
    return reduceRouteItems(this.routeReduce(items))
  }

  render () {
    const props = {
      routeItems: this.getRouteItems(),
      basePath: this.basePath,
      routeParams: {
        // combine current route params and application context params
        ...this.$context.params,
        ...this.$route.params
      },
      menuClass: 'side-menu',
      collapse: !this.$store.getters.sidebar.opened
    }
    return (
      <RouteMenu props={props} />
    )
  }
}
