type ResourceCode = string;
type ACLConfigItem = string | string[];

interface ACLRefs {
  [key: ResourceCode]: ACLConfigItem
}

export const aclTables: ACLRefs = {
  1: '/workbench/*',
  2: '/systemManage/*'
}
