import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator'
import { Action, Getter, Mutation, State } from 'vuex-class'

import Hamburger from '@/components/Hamburger/index.vue'
import { IUserInfo } from '@/components/User/types/user'

import Topnav from './Topnav'

import './Navbar.scss'

@Component({
  components: {
    Hamburger,
    Topnav
  }
})
export default class Navbar extends Vue {
  @Prop({ type: [String, Number], default: 50 })
  height!: number | string

  @Getter('sidebar')
  sidebar!: any

  @Getter('app/rootPath')
  rootPath!: string;

  @Getter('session/userInfo')
  userInfo!: IUserInfo;

  @Action('app/toggleSideBar')
  toggleSideBar!: IGenericAction

  logout () {
    this.$confirm($t('Are you sure to logout?'), $t('Logout'), { type: 'warning' })
      .then(
        async () => {
          try {
            const result = await this.$store.dispatch('session/logout')
            const returnUrl = result.redirectURL
            if (returnUrl) {
              location.replace(returnUrl)
            } else {
              this.$router.push(`/login`)
            }
          } catch (e) {}
        }
      )
  }

  // 个人信息设置
  goProfile () {
    this.profileRef.profiled.show()
  }

  // 重置密码操作
  goPassword () {
    this.resetPwdDlgRef.inner.show(this.userInfo)
  }

  // 渲染
  render () {
    const {
      sidebar,
      userInfo,
      $slots: { default: children },
      rootPath
    } = this
    if (!userInfo) {
      return null
    }
    return (
      <el-header class="app-navbar" height={String(this.height)}>
        { children || null }
        <Hamburger isActive={sidebar.opened} class="hamburger-container" onToggleClick={this.toggleSideBar} />
        <Topnav basePath={rootPath} class="fl" />
        <div class="right-menu">
          <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
            <div class="avatar-wrapper">
              {
                userInfo.avatar
                  ? (<img src={userInfo.avatar} class="user-avatar" />)
                  : (<Icon iconName="avatar" />)
              }
              <span class="user-name">{ userInfo.username }</span>
              <i class="el-icon-arrow-down el-icon--right" />
            </div>
            <el-dropdown-menu slot="dropdown">
              {/* <el-dropdown-item icon="iconfont td-icon-user" nativeOnClick={ this.goProfile }>{ $t('Profile') }</el-dropdown-item>
              <el-dropdown-item icon="iconfont td-icon-key" nativeOnClick={ this.goPassword }>{ $t('Reset Password') }</el-dropdown-item> */}
              <el-dropdown-item style="border-top: 1px solid #eee;margin-top:3px;" icon="iconfont td-icon-poweroff" nativeOnClick={ this.logout }>{ $t('Logout') }</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
    )
  }
}
