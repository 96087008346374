import { Component, Vue, Watch, Prop } from 'vue-property-decorator'

import { isExternal } from '@/utils/validate'

const linkProps = (url: string) => {
  if (isExternal(url)) {
    return {
      is: 'a',
      attrs: { href: url, target: url }
    }
  }
  return {
    is: 'router-link',
    props: { to: url }
  }
}

@Component({
  functional: true
})
export default class Link extends Vue {
  @Prop({ type: String, required: true })
  to!: string;

  render (h, context) {
    const {
      scopedSlots,
      props: { to }
    } = context
    const props = linkProps(to)
    return (
      <component { ...{ ...props } }>
        { scopedSlots.default ? scopedSlots.default() : null }
        { scopedSlots.title ? scopedSlots.title() : null }
      </component>
    )
  }
}
