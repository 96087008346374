export interface AuthErrorData extends Kv {
  redirectURL: string;
}

export class AuthError extends Error {
  code: number = 401
  data: AuthErrorData | null = null
  constructor (err: any) {
    let {
      data = null,
      code = 401,
      message = 'Authorization Error'
    } = err

    // @ref {AxiosResponse}
    const result = err.response?.data
    if (result) {
      data = result.data
      message = result.message
    }

    super(message)

    this.data = data
    this.code = code
  }
}
