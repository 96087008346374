import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { on, off } from '@tdio/dom-utils'
import { debounce } from '@tdio/utils'
import { reduceTree, resolveRoutePath } from 'route-utils'

import { isExternal } from '@/utils/validate'

import RouteItem from './RouteItem'

const resolve = (route: RouteConfig, base: string, params: Kv): string => {
  if (isExternal(route.path)) {
    return route.path
  }
  return resolveRoutePath(route, { base, params, append: true })
}

@Component({
  components: { RouteItem }
})
export default class RouteMenu extends Vue {
  @Prop({ type: Array, default: () => ([]), required: true })
  routeItems!: RouteConfig[];

  @Prop({ type: Object, default: () => {} })
  routeParams!: string;

  @Prop({ type: String, default: '' })
  menuClass!: string;

  @Prop({ type: String, default: '' })
  basePath!: string;

  @Prop()
  collapse!: boolean;

  @Prop({ type: Boolean, default: true })
  uniqueOpened!: boolean;

  @Prop({ type: Number, default: 2 })
  maxDepth!: number;

  menuHeight: string | number = '';

  @Watch('$route')
  handleRouteChange (to: any) {
    const menu: any = this.$refs.menu
    const { items, activeIndex } = menu
    let path = to.path
    // None menu for current active, We need to expand the nearest menu by the route path
    let submenu
    while (path && !(submenu = items[path] || items[`${path}/`])) {
      path = path.substring(0, path.lastIndexOf('/')) // slice last step path
    }
    path = submenu && submenu.index || path
    if (submenu && activeIndex !== path) {
      menu.updateActiveIndex(path)
    }
  }

  syncHeight () {
    const el = this.$el
    this.menuHeight = `${document.body.offsetHeight - el.getBoundingClientRect().top}px`
  }

  updated () {
    this.$nextTick(() => {
      this.handleRouteChange(this.$route)
    })
  }

  mounted () {
    this.handleRouteChange(this.$route)

    // sync wrapper height
    const f = this.syncHeight
    f()
    on(window, 'resize', f)
    this.$on('hook:beforeDestroy', () => {
      off(window, 'resize', f)
    })

    // update scrollbar view
    const menu: any = this.$refs.menu
    if (menu) {
      const refresh = debounce(() => (this.$refs.scrollView as any).update(), 200)
      menu.$on('open', refresh)
      menu.$on('close', refresh)
    }
  }

  render () {
    const {
      maxDepth,
      routeParams: params,
      basePath: base,
      uniqueOpened
    } = this

    let menuItems = this.routeItems
    if (maxDepth > 0) {
      menuItems = reduceTree(menuItems, (_parent, _node, _path, level) => level < maxDepth)
    }

    // for route items redirect path issues. set defaultAcitve by menu api instead.
    return (
      <el-scrollbar ref="scrollView" height={this.menuHeight} wrapClass="scrollbar-wrapper" class="v-scrollbar--y">
        <el-menu
          ref="menu"
          class={this.menuClass}
          collapse={this.collapse}
          collapseTransition={false}
          uniqueOpened={uniqueOpened}
          mode="vertical"
        >
          {
            menuItems.map(r => {
              const path = resolve(r, base, params)
              return <RouteItem class="route-menu-item" key={path} item={r} basePath={base} params={params} disabled={!!r.disabled} />
            })
          }
        </el-menu>
      </el-scrollbar>
    )
  }
}
