/**
 * Provide some global utility filters.
 *
 * @author Allex Wang
 */

import { isValue, template, isEmpty, defaultTo } from '@tdio/utils'
import { composeFilter } from '@tdio/vue-utils'

import {
  collectEnumKeys
} from '../util'

export {
  truncate
} from '@tdio/tdui/lib/utils'

export {
  formatDate,
  timeAgo
} from '../date'

const i18n = (s: string, ...args: any[]): string => $t(s, ...args)

// i18n shortcut
const t = i18n

export { i18n, t }

/* format decimal number, 10000000 -> 10,000,000 */
export function numberFormatter (num: number, digits: number = 1): string {
  return (+num || 0).toFixed(digits).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}

// 分转元并格式化, 100分 -> 1元
export function toFenFormatter (num: number, digits: number = 2): string {
  return numberFormatter(+(num / 100).toFixed(digits), digits)
}

// enums: [ TrueText, FalseText ]
export function toBoolText (v: any, enums: [ string, string ] = ['Yes', 'No']) {
  return enums[Number(!v)]
}

export function count (list: any[]) {
  return list && list.length || 0
}

export function format (v: any, format: string) {
  return template(format, v)
}

export function empty <T> (v: T, emptyText: string = '-'): string {
  return v !== 0 && isEmpty(v) ? emptyText : String(v)
}

export const emptyFilter = empty

export function enumText (v: any, enumRef: any, defaultVal: string | number) {
  const s = defaultTo(enumRef && enumRef[v], v)
  return isValue(s) ? s : defaultVal
}

// Filter for translate enum types
export function translateEnumText (v: string, enumNS: string) {
  const path = enumNS
    ? (template.isListValuePattern(enumNS) ? template(enumNS, v) : `${enumNS}.${v}`)
    : v
  return $t.te(path) ? $t(path) : $t(v)
}

export function enumFormatter (v: string | number, enumPattern: string, enumRef: any): string {
  if (!template.isListValuePattern(enumPattern)) {
    enumPattern = [enumPattern, '%{0}'].filter(Boolean).join('.')
  }
  return composeFilter([
    [enumText, enumRef], // transform enum value to Enum key
    [format, enumPattern],
    (v: string) => ($t.te(v) ? $t(v) : $t(v.split('.').pop()!))
  ])(v) as string
}

/**
 * Generate options by a Enum reference class.
 */
export function toEnumOptions (enumRef: any, enumPattern: string): Array<ui.IOption & { key: any }> {
  return collectEnumKeys(enumRef).map(key => {
    const value = defaultTo(enumRef[key], key)
    return {
      key,
      value,
      label: translateEnumText(key, enumPattern)
    }
  })
}

export function spaceFilter (n: number | string, isDecimal: boolean = false): string {
  let v: number
  try {
    v = parseInt(n as string, 10) || 0
  } catch (e) {
    v = 0
  }
  const thresh = isDecimal ? 1000 : 1024
  if (Math.abs(v) < thresh) {
    return `${v} B`
  }

  const units = ['K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y']
  let u = -1
  do {
    v /= thresh
    ++u
  } while (Math.abs(v) >= thresh && u < units.length - 1)

  return `${v.toFixed(2)} ${units[u]}${isDecimal ? '' : 'iB'}`
}
