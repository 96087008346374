

















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import getPrefs from '@/config/getConfig'

@Component
export default class Logo extends Vue {
  @Prop(Boolean)
  collapse!: boolean;

  @Prop(Boolean)
  darkMode!: boolean

  title = getPrefs('sys.appName')
  appLogo = getPrefs('sys.appLogo')

  get appBrandImage () {
    return getPrefs(this.darkMode ? 'sys.appBrandImageLight' : 'sys.appBrandImage')
  }

  get collapseLogo () {
    return this.appBrandImage || this.appLogo
  }
}
