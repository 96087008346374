import { mapACLTables, ACLTable } from '@/components/ACLs/util'
import { IUserAuthInfo } from '@/components/User/types/user'

import { initModuleAccessor, ModuleAccessor } from './moduleAccessor'

export class Session {
  userInfo: IUserAuthInfo | null = null;
  acl: ACLTable | null = null;
  macc: ModuleAccessor | null = null;

  constructor (userInfo?: IUserAuthInfo) {
    if (userInfo) {
      this.setAuthInfo(userInfo)
    }
  }

  setAuthInfo (info: IUserAuthInfo | null) {
    this.userInfo = info
    this.macc = initModuleAccessor(info?.featureBlacklist || [])
  }

  setACL (permissions: string[] | null) {
    // Sets ACL tables, normalize to acl k/v tables (aka Kv<IPermission>)
    this.acl = permissions ? mapACLTables(permissions) : null
  }
}
