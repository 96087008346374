const localeMessages = {
  App: '应用',
  Apps: '应用管理',
  'App Name': '应用名称',
  'New App': '创建应用',
  'Edit App': '修改应用',
  'App Detail': '应用详情',
  'App Entrypoint': '应用入口',
  'Delete App': '删除应用',
  'App Privileges': '应用权限池',
  'App Roles': '应用角色',
  'App Master': '应用管理员',
  'Re-generate': '重新生成',
  'Callback URL': '系统回调地址',

  // privilege tree
  Privilege: '权限',
  'Privilege Name': '权限名称',
  'New Privilege': '创建权限',
  'Modify Privilege': '修改权限',
  'Delete Privilege': '删除权限',
  'Node Name': '节点名',
  'Parent Node': '父级节点',
  'Add Child': '添加子节点',
  'Modify Node': '修改节点',
  'privilege item': '权限项',
  Sort: '排序',
  Path: '路径',
  'Privilege Key': '权限 Key',

  'Import Menu': '导入菜单',
  'Export Menu': '导出菜单',
  Certification: '认证协议'
}

const appRules = {
  rule: {
    appName: '允许中文、字母、数字、"-"、"_", 不能以"-"开头, 2~50个字符'
  }
}

export default { ...localeMessages, ...appRules }
