import PureRouterView from '@/components/common/PureRouterView'
import { Layout } from '@/layout'
import { RouteType } from '@/router/types'

import { posterManage, articleManage, articleCategory } from './workbench'
import { memberManage, approvalManage } from './system'

export { constRoutes } from '../config'

const workbench = {
  path: 'work',
  meta: { name: 'Workbench', icon: 'menu-app-manage.svg', type: RouteType.Top },
  component: Layout,
  children: [
    articleCategory,
    articleManage,
    posterManage
  ]
}

const systemManage = {
  path: 'sys',
  meta: { name: 'System Manage', icon: 'setting.svg', type: RouteType.Top },
  component: Layout,
  children: [
    memberManage,
    approvalManage
  ]
}

export const asyncRoutes = [
  {
    path: '/',
    component: PureRouterView,
    hidden: true,
    meta: { title: 'Home' },
    children: [
      workbench,
      systemManage
    ]
  }
]
