/*
 * Common validator rules, eg. getRule('rule.xxx')
 */

const commonRules = {
  phone: '请输入正确的手机号',
  email: '请输入正确的邮箱',
  port: '请输入1~65535之间的数字',
  absolutePath: '绝对路径，以“/”开头',
  relativePath: '相对路径',
  fileName: '',
  noChinese: '不允许中文',
  url: 'URL 地址不合法',
  remark: '%{0} ~ %{1}个字符',
  range: '整数%{0} ~ %{1}',
  required: '必填',
  integerInfo: '必须为正整数',
  maxLength: '不超过%{0}个字符'
}

const userRules = {
  username: '用户名允许字母、数字、"-"、"_", 3~20个字符',
  password: '密码必须为6~20位数字和字符的组合',
  realName: '允许中文、字母、数字、"-"、"_", 2~20个字符',
  roleName: '角色名允许中文、字母、数字、"-"、"_", 2~30个字符',
  tenantName: '允许中文、字母、数字、"-"、"_", 不能以"-"开头, 2~50个字符',
  confirmPasswordRequired: '请输入确认密码',
  confirmPasswordMismatch: '确认密码不匹配'
}

const validatorRuleMessages = {
  Required: '必填',
  rule: {
    ...commonRules,
    ...userRules
  }
}

export default validatorRuleMessages
