import qs from 'querystring'

import { hasOwn } from '@tdio/utils'

import { reIp } from '@/config/regexs'

export {
  hasOwn,
  noop,
  pick,
  makeError,
  debounce,
  typeOf, isString, isNumber, isNumeric, isBoolean, isArray, isFunction, isObject, isNil, isPlainObject, isPrimitive, isPromise,
  isValue, isEmpty,
  set as setv,
  get as getv,
  deepClone,
  deepAssign,
  guid,
  random,
  template as format,
  sequence
} from '@tdio/utils'

export {
  parseBase64, toBase64, b64Encode, b64Decode
} from '@tdio/tdui/lib/utils'

export function stringifyQuery <T> (o: T): string {
  return qs.stringify(o as Record<string, any>)
}

export function parseQuery (query: string): Record<string, any> | null {
  return query && qs.parse(query.replace(/^[?&]/, '')) || null
}

export function getQuery <T> (key?: string, query?: string): T {
  if (query) {
    // strip url path prefix
    query = query.substring(query.indexOf('?'))
  } else {
    query = location.search
  }
  const obj = parseQuery(query)
  return (key ? obj && obj[key] : obj)
}

export function newPager (page: number = 1, size: number = 10) {
  return { page, size }
}

export function pluck <T = string> (list: any[], propertyName: string): T[] {
  return list.map(l => l[propertyName] as T)
}

export function arrayEquals <T> (a1: T[], a2: T[], comparator: (v1: T, v2: T) => boolean) {
  if (a1.length !== a2.length) {
    return false
  }
  for (let i = 0; i < a1.length; ++i) {
    if (!comparator(a1[i], a2[i])) {
      return false
    }
  }
  return true
}

export const sortIp = (a: string, b: string) => {
  if (!reIp.test(a) || !reIp.test(b)) return 0

  const as = a.split('.').map(v => Number(v))
  const bs = b.split('.').map(v => Number(v))
  for (let i = 0; i < 4; i++) {
    if (as[i] !== bs[i]) {
      return as[i] - bs[i]
    }
  }
  return 0
}

export const getEnumKey = <T> (o: T, v: any): string | undefined => {
  /* eslint-disable no-restricted-syntax */
  for (const k in o) {
    if (hasOwn(o, k) && o[k] === v) {
      return k
    }
  }
  return undefined
}

// 权限树排序
export function sortTreeData (data: Kv | Kv[]) {
  let arr
  if (Array.isArray(data)) {
    arr = data
  } else {
    arr = [data]
  }
  arr.sort((a, b) => a.priority - b.priority)
  arr.forEach(item => {
    if (item.children) {
      item.children = sortTreeData(item.children)
    }
  })
  return arr
}
