import { Button } from '@tdio/tdui'
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { isArray } from '@tdio/utils'
import { debounce } from '@tdio/vue-utils'

@Component
export class Search extends Vue {
  @Prop({ default: '' })
  searchKey!: string | string[]

  @Prop({ type: String, default: '' })
  value!: string;

  @Prop({ type: String, default: '' })
  placeholder!: string;

  @Prop({ required: true })
  onSearch!: (e: Event, query: string) => Promise<any>

  @Ref('btn')
  $btn!: Button;

  @debounce(50)
  handleSearch (e?: Event) {
    // handle enter if keyup
    if (!e || (e.keyCode || e.which) === 13) {
      this.$btn.$emit('click')
    }
  }

  render () {
    const {
      searchKey,
      $attrs,
      $listeners,
      $slots: {
        default: prepend
      }
    } = this

    const placeholder = this.placeholder || $t('SearchKey', isArray(searchKey) ? searchKey.join('、') : searchKey)

    return (
      <el-input
        class="v-search"
        value={this.value}
        attrs={$attrs}
        placeholder={placeholder}
        clearable
        nativeOnKeyup={this.handleSearch}
        on={{ ...$listeners, clear: this.handleSearch }}
      >
        {
          [
            prepend && (<template slot="prepend">{ prepend }</template>) || null,
            <Button slot="append" ref="btn" icon="el-icon-search" onClick={(e: Event) => this.onSearch(e, this.value)} />
          ]
        }
      </el-input>
    )
  }
}
