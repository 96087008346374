import { merge } from '@tdio/utils'

import {
  commonMenus,
  commonActions,
  validatorRuleMessages, // common rules: rule.*
  loginLocales,
  userLocales
} from './common'
import { basicDataFields } from './common/dataFields'
import { globalMessages } from './common/messages'
import appManages from './apps'
import authManages from './auth'
import loginManages from './loginManage'
import workbench from './workbench'
import systemManage from './systemManange'

// eslint-disable-next-line prefer-spread
const manyMerge = (...args: Kv[]) => args.reduce<Kv>((p, o) => merge(p, o), {})

const localeMessages = manyMerge(
  /* common messages */
  commonMenus,
  commonActions,
  globalMessages,

  /* common rules: rule.* */
  validatorRuleMessages,

  /* basic data column fields */
  basicDataFields,

  /* login & users */
  loginLocales,
  userLocales,

  /* app manage */
  appManages,

  /* auth manage */
  authManages,

  /* login manage */
  loginManages,
  workbench,
  systemManage
)

export default localeMessages
