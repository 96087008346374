import { Component, Vue, Watch } from 'vue-property-decorator'
import { resolveRoutePath } from 'route-utils'
import { get } from '@tdio/utils'

@Component
export default class AppMain extends Vue {
  transitionName: string = '';

  get layoutMeta () {
    const meta = this.$route.meta
    const layoutRoute = this.$router.currentRoute.matched.find(route => route.meta.layout)
    return { ...meta, ...(layoutRoute && layoutRoute.meta || {}) }
  }

  get classes () {
    const { className } = this.layoutMeta
    return [
      'app-main', { [className]: !!className }
    ]
  }

  @Watch('$route')
  handleRouteChange (to: any, from: any) {
    this.transitionName = this.layoutMeta.transition === false ? '' : 'fade-transform'
  }

  render (h: any) {
    const { matched, path, params } = this.$route

    let last: string | null
    const key = matched.some(m => {
      // Isolate neast layout renderer optimization.
      if (last && get(m, 'meta.layout')) {
        return true
      }
      return (last = m.path, false)
    }) ? resolveRoutePath(last, { params }) : path

    return (
      <el-main class={this.classes}>
        <transition name={this.transitionName} mode="out-in">
          <router-view key={key} />
        </transition>
      </el-main>
    )
  }
}
