import { Component, Vue, Watch, Prop } from 'vue-property-decorator'

import Link from './Link'

@Component({
  functional: true,
  components: { Link }
})
export default class MenuItem extends Vue {
  @Prop(String)
  icon!: string;

  @Prop(String)
  title!: string;

  @Prop(String)
  to!: string;

  render (h, context) {
    const { icon, title, to } = context.props
    if (!to) {
      return [
        icon ? (<Icon iconName={icon} />) : null,
        title ? (<span slot="title">{title}</span>) : null
      ]
    }
    return [
      context.children,
      <Link
        {
          ...{
            props: {
              to
            },
            scopedSlots: {
              default: scope => (icon ? (<Icon iconName={ icon } />) : null),
              title: scope => (title ? (<span>{ title }</span>) : null)
            }
          }
        }
      />
    ]
  }
}
